import {useState} from "react";
import {formatProductPlans} from "../utils/format";
import {v4 as uuid} from "uuid";

export default function useProductPlans(productPlans) {

    const [supportedPlans, setSupportedPlans] = useState(formatProductPlans(productPlans))

    const removePlan = (vertical, plan) => {
        if (!supportedPlans[vertical] || !plan) {
            return supportedPlans
        }
        const plans = {
            ...supportedPlans,
            [vertical]: supportedPlans[vertical].filter((item) => item.id !== plan.id)
        }
        if (!plans[vertical] || plans[vertical].length === 0) {
            delete plans[vertical]
        }
        return plans
    }

    const handlePlanUndo = () => {
        setSupportedPlans(formatProductPlans(productPlans))
    }

    const handlePlanRemove = (vertical, plan) => {
        setSupportedPlans(removePlan(vertical, plan))
    }

    const handlePlanUpdate = (vertical, plan) => {
        if (!supportedPlans[vertical] || !plan || !plan.vertical) {
            return;
        }
        // remove old record
        const plans = removePlan(vertical, plan)
        // add updated record
        handlePlanAdd(plan.vertical, plan, plans)
    }

    const handlePlanAdd = (vertical, plan, existingPlans) => {
        if (!plan || !plan.pricingGrid || !plan.mdrGrid) {
            return;
        }
        if (!existingPlans) {
            existingPlans = supportedPlans
        }
        if (!plan.id) {
            plan.id = uuid()
        }
        const verticalPlans = !!existingPlans[vertical] ? existingPlans[vertical].filter((item) => item.pricingGrid !== plan.pricingGrid || item.mdrGrid !== plan.mdrGrid) : []
        if (plan.defaultPlan) {
            verticalPlans.forEach(item => item.defaultPlan = false)
        }
        verticalPlans.push(plan)
        setSupportedPlans({
            ...existingPlans,
            [vertical]: verticalPlans
        })
    }

    return {supportedPlans, handlePlanAdd, handlePlanUpdate, handlePlanRemove, handlePlanUndo}
}