import React from "react";
import {useSelector} from "react-redux";

import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Chip from "@material-ui/core/Chip";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import Panel from "./Panel";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import ReloadButton from "./ReloadButton";
import Alert from "./Alert";
import {loanStatuses} from "../utils/constants";
import FormInput from "./FormInput";
import LoanInfoDialog from "./LoanInfoDialog";
import useApiRequest from "../hooks/useApiRequest";
import useFields from "../hooks/useFields";

const formFields = {
    "status": {
        label: 'Status',
        type: 'select',
        data: loanStatuses,
        initValue: 'PENDING'
    },
    "limit": {
        label: 'Limit',
        type: 'number',
        initValue: 10
    }
}

const fieldList = [
    "status",
    "limit"
]

const requestType = 'recent-loans';

export default function RecentLoansPanel({submitRequest, openPaymentLink}) {
    const loans = useSelector(state => state.console.recentLoans);
    const formsData = useSelector(state => state.console.formsData[requestType]);
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();
    const [fields, handleOnChange] = useFields(formFields, formsData, fieldList);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [selectedRow, setSelectedRow] = React.useState('');

    const handleReload = () => {
        submitRequest(requestType, fields, newRequest());
    }

    const handleTransactionOpen = (row) => {
        setSelectedRow(row);
        setOpenDialog(true);
    }

    const handleDialogClose = () => {
        setOpenDialog(false);
    }

    const renderIDColumn = (row) => {
        if (!row || !row.id) {
            return null;
        }
        const isSelected = !!selectedRow && selectedRow.id === row.id
        const deleteIcon = isSelected ? <CheckCircleIcon/> : null;
        const onDelete = isSelected ? () => setSelectedRow(null) : null;
        return <CopyToClipboard text={row.id}>
            <Chip
                label={row.id}
                size="small"
                onClick={() => handleTransactionOpen(row)}
                deleteIcon={deleteIcon}
                onDelete={onDelete}
            />
        </CopyToClipboard>
    }

    const LoansTable = () => {
        if (!loans || requestInProgress) {
            return null;
        }
        if (loans.length === 0) {
            return <Alert severity="info">Loan applications not found.</Alert>
        }
        return (
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loans.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell>
                                {renderIDColumn(row)}
                            </TableCell>
                            <TableCell>{row.createdAt}</TableCell>
                            <TableCell>{row.status}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        )
    }

    return (
        <Panel title="Recent Loan Applications" height={600}>
            <div style={{marginBottom: '10px'}}>
                {fieldList.map((name) => (
                    <FormInput
                        disabled={requestInProgress}
                        key={name}
                        name={name}
                        onChange={handleOnChange}
                        getEditValue={name => fields[name]}
                        formFields={formFields}
                    />
                ))}
            </div>
            <ReloadButton hide={requestInProgress} onClick={handleReload}/>
            <LoansTable/>
            <ErrorMessage errorMessage={requestError}/>
            <Progress show={requestInProgress} requestId={requestId}/>
            {openDialog && <LoanInfoDialog
                submitRequest={submitRequest}
                openPaymentLink={openPaymentLink}
                onClose={handleDialogClose}
                loanId={selectedRow.id}
            />}
        </Panel>
    )
}
