import React from "react";
import {Controller} from "react-hook-form";
import FormInput from "./FormInput";

export const FormInputCtr = ({control, name, formFields, disabled, hide}) => {
    if (hide) {
        return null
    }
    return <>
        <Controller
            name={name}
            control={control}
            defaultValue={formFields[name].initValue}
            rules={formFields[name].rules}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormInput
                    error={error ? error.message : null}
                    disabled={disabled}
                    name={name}
                    onChange={onChange}
                    getEditValue={() => value}
                    formFields={formFields}
                />
            )}
        />
    </>
}

