import React from "react";
import {useSelector} from "react-redux";
import {useForm} from "react-hook-form";

import useApiRequest from "../hooks/useApiRequest";
import {getFieldList} from "../utils/format";
import ExPanel from "./ExPanel";
import {Button, DialogActions, Grid, Tooltip} from "@material-ui/core";
import {FormInputCtr} from "./FormInputCtr";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import JsonSwitch from "./JsonSwitch";
import useSettings from "../hooks/useSettings";
import JsonView from "./JsonView";
import {DataGrid} from "@mui/x-data-grid";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import AutorenewIcon from '@material-ui/icons/Autorenew';

const IndustryList = ({columns, loading, jsonView, items}) => {
    if (!items) {
        return null;
    }
    if (jsonView) {
        return (
            <JsonView data={items} shouldExpandNode={(keyPath, data, level) => level < 1}/>
        )
    }
    return <div style={{height: 400, width: '100%'}}>
        <DataGrid
            getRowId={(row) => row.name}
            rows={items}
            hideFooterSelectedRowCount={true}
            autoPageSize={true}
            columns={columns}
            loading={loading}
            density='compact'
        />
    </div>
}


const getRequestType = 'industry-list';
const updateRequestType = 'industry-update';
const removeRequestType = 'industry-remove';

const formFields = {
    name: {
        label: 'Industry Name',
        initValue: '',
        order: 1
    },
    vertical: {
        label: 'Vertical',
        initValue: '',
        type: 'select',
        data: [],
        order: 2
    },
    limit: {
        label: 'Limit',
        initValue: '100',
        order: 3
    }
}
const fieldList = getFieldList(formFields)

export default function IndustryPanel({submitRequest}) {
    const industryList = useSelector(state => state.console.industryList);
    const formsData = useSelector(state => state.console.formsData[getRequestType]);
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();
    const [jsonView, setJsonView] = React.useState(false);

    const dataPresent = !!industryList

    useSettings(formFields)

    const { handleSubmit, control, resetField, setValue } = useForm({
        defaultValues: {
            ...formsData
        }
    });

    const onGet = (fields) => {
        submitRequest(getRequestType, fields, newRequest());
    }

    const onUpdate = (fields) => {
        submitRequest(updateRequestType, fields, newRequest());
    }

    const onRemove = (name) => {
        submitRequest(removeRequestType, {name}, newRequest());
    }

    const onCopy = (data) => {
        setValue('name', data.name)
        setValue('vertical', data.vertical)
    }

    const handleOnClean = () => {
        fieldList.forEach(name => resetField(name, {defaultValue: formFields[name].initValue}));
    }

    const operationsCellRenderer = ({row}) => {
        return <>
            <Tooltip title={"Remove"}>
                <HighlightOffIcon color={"action"} cursor={"pointer"} onClick={() => onRemove(row.name)}/>
            </Tooltip>
            <Tooltip title={"Copy to form"}>
                <AutorenewIcon color={"action"} cursor={"pointer"} onClick={() => onCopy(row)}/>
            </Tooltip>
        </>
    }

    const columns = [
        {field: 'name', headerName: 'Name', width: 400},
        {field: 'vertical', headerName: 'Vertical', width: 150},
        {field: 'operations', headerName: 'Op', width: 150, renderCell: operationsCellRenderer}
    ]

    return (
        <ExPanel title="Industry List">
            <Grid item xs={12}>
                <form>
                    {fieldList.map((name) => (
                        <FormInputCtr
                            key={name}
                            name={name}
                            formFields={formFields}
                            control={control}
                            disabled={requestInProgress}
                        />
                    ))}
                </form>
                <DialogActions>
                    <Button disabled={requestInProgress} variant="contained" color="primary" onClick={handleOnClean}>Clean</Button>
                    <Button disabled={requestInProgress} variant="contained" color="primary" onClick={handleSubmit(onGet)}>Get</Button>
                    <Button disabled={requestInProgress} variant="contained" color="primary" onClick={handleSubmit(onUpdate)}>Update/Add</Button>
                </DialogActions>
                <ErrorMessage errorMessage={requestError}/>
                <Progress show={requestInProgress} requestId={requestId}/>
                <JsonSwitch hide={!dataPresent} jsonView={jsonView} setJsonView={setJsonView}/>
                {dataPresent &&
                    <IndustryList loading={requestInProgress} jsonView={jsonView} items={industryList} columns={columns}/>
                }
            </Grid>
        </ExPanel>
    )
}