import React from "react";
import {
    Button,
    Chip, Dialog, DialogActions, DialogContent, DialogTitle,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Tooltip,
    Typography
} from "@material-ui/core";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import CheckIcon from "@material-ui/icons/Check";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import EditIcon from '@material-ui/icons/Edit';
import UndoIcon from '@material-ui/icons/Undo';
import DraggableDialog from "./DraggableDialog";
import {getFieldList} from "../utils/format";
import {useForm} from "react-hook-form";
import {FormInputCtr} from "./FormInputCtr";
import useSettings from "../hooks/useSettings";

const ProductPlan = ({vertical, plan, onRemove, onSave}) => {
    const [openDialog, setOpenDialog] = React.useState(false);

    const handleSave = (data) => {
        setOpenDialog(false)
        onSave(data)
    }

    return <>
        <TableRow>
            <TableCell/>
            <TableCell>
                {plan.pricingGrid}
            </TableCell>
            <TableCell>
                {plan.mdrGrid}
            </TableCell>
            <TableCell>
                {plan.defaultPlan ? (<CheckIcon color={"action"} fontSize={"small"}/>) : ''}
            </TableCell>
            <TableCell>
                <Tooltip title={"Edit"}>
                    <EditIcon color={"action"} cursor={"pointer"} onClick={() => setOpenDialog(true)}/>
                </Tooltip>
                <Tooltip title={"Remove"}>
                    <HighlightOffIcon color={"action"} cursor={"pointer"} onClick={onRemove}/>
                </Tooltip>
            </TableCell>
        </TableRow>
        {openDialog && <EditPlanDialog onClose={() => setOpenDialog(false)} onSave={handleSave} data={{...plan, vertical}}/>}
    </>
}

const VerticalPlans = ({vertical, plans, onRemove, onUpdate}) => {
    return <>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
            <TableCell>
                <Chip label={vertical} size="small" color="primary"/>
            </TableCell>
            <TableCell colSpan={4}/>
        </TableRow>
        <>
            {plans.map((plan) => (<ProductPlan key={`${plan.pricingGrid}:${plan.mdrGrid}`}
                                               plan={plan}
                                               vertical={vertical}
                                               onRemove={() => onRemove(plan)}
                                               onSave={onUpdate}
            />))}
        </>
    </>
}

const formFields = {
    "vertical": {
        label: 'Vertical',
        data: [],
        type: 'select',
        initValue: '',
        rules: {
            required: 'Vertical required'
        },
        order: 1
    },
    "pricingGrid": {
        label: 'Pricing Grid',
        data: [],
        type: 'select',
        initValue: '',
        rules: {
            required: 'Pricing Grid required'
        },
        order: 2
    },
    "mdrGrid": {
        label: 'MDR Grid',
        data: [],
        type: 'select',
        initValue: '',
        rules: {
            required: 'MDR Grid required'
        },
        order: 3
    },
    "defaultPlan": {
        label: 'Default',
        type: 'switch',
        initValue: false,
        order: 4
    }
}
const formFieldList = getFieldList(formFields)

const EditPlanDialog = ({onClose, onSave, data}) => {

    if(!data) {
        data = {
            vertical: "",
            pricingGrid: "",
            mdrGrid: "",
            defaultPlan: false
        }
    }

    const { handleSubmit, control } = useForm({
        defaultValues: {
            ...data
        }
    });
    useSettings(formFields)

    return <Dialog
        PaperComponent={DraggableDialog}
        aria-labelledby="draggable-dialog-title"
        open={true}
    >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            Product Plan
        </DialogTitle>
        <DialogContent dividers={true}>
            <form>
                {formFieldList.map((name) => (
                    <FormInputCtr
                        key={name}
                        name={name}
                        formFields={formFields}
                        control={control}
                    />
                ))}
            </form>
        </DialogContent>
        <DialogActions>
            <Button variant="contained" color="primary" onClick={handleSubmit(onSave)}>Save</Button>
            <Button variant="contained" onClick={onClose}>Close</Button>
        </DialogActions>
    </Dialog>
}

export default function ProductPlanList({supportedPlans, onRemove, onAdd, onUpdate, onUndo}) {

    const [openDialog, setOpenDialog] = React.useState(false);

    const handleAddPlan = ({vertical, pricingGrid, mdrGrid, defaultPlan}) => {
        onAdd(vertical, {pricingGrid, mdrGrid, defaultPlan})
        setOpenDialog(false)
    }

    return <>
        <Typography variant="h6" gutterBottom component="div">
            Supported Product Plans
        </Typography>
        <TableContainer component={Paper}>
            <Table size="small" aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell>Vertical</TableCell>
                        <TableCell>Pricing Grid</TableCell>
                        <TableCell>MDR Grid</TableCell>
                        <TableCell>Default</TableCell>
                        <TableCell>
                            <Tooltip title={"Add"}>
                                <ControlPointIcon color={"primary"} cursor={"pointer"} onClick={() => setOpenDialog(true)}/>
                            </Tooltip>
                            <Tooltip title={"Undo"}>
                                <UndoIcon color={"primary"} cursor={"pointer"} onClick={onUndo}/>
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    { supportedPlans &&
                    Object.entries(supportedPlans).map(([k, v]) => {
                        return <VerticalPlans key={k}
                                              vertical={k}
                                              plans={v}
                                              onRemove={(plan) => onRemove(k, plan)}
                                              onUpdate={(plan) => onUpdate(k, plan)}
                        />
                    })
                    }
                </TableBody>
            </Table>
        </TableContainer>
        {openDialog &&
            <EditPlanDialog onClose={() => setOpenDialog(false)} onSave={handleAddPlan}/>
        }
    </>
}
