import React from "react";
import {useSelector} from "react-redux";
import {highlight, languages} from "prismjs/components/prism-core";
import Editor from "react-simple-code-editor";

import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import {Grid, Button, Chip, DialogActions} from "@material-ui/core";
import {DataGrid} from "@mui/x-data-grid";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DateFnsUtils from "@date-io/date-fns";

import ExPanel from "./ExPanel";
import FormInput from "./FormInput";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import JsonSwitch from "./JsonSwitch";
import JsonView from "./JsonView";
import useFields from "../hooks/useFields";
import useApiRequest from "../hooks/useApiRequest";
import Alert from "./Alert";
import {formatUT} from "../utils/format";
import Link from "@material-ui/core/Link";
import {isElectron} from "../utils/electron";

const formFields = {
    "production": {
        label: "Production",
        type: isElectron() ? "switch" : "hidden",
        initValue: false,
    },
    "region": {
        label: "Region",
        type: "select",
        data: [
            {id: "us-east-1"},
            {id: "us-west-2"}
        ],
        initValue: "us-east-1",
    },
    "queryString": {
        label: 'Query String',
        initValue: 'fields @timestamp \n' +
            '| filter @message like /sourcetype/ and @message not like /(?i)warmup/ \n' +
            '#| filter @message like /(?i)error/ \n' +
            '| parse "{\\"event\\":\\"*\\",\\"host\\":\\"*\\",\\"source\\":\\"*\\",\\"sourcetype\\":\\"*\\"}" as event, host, @source, sourcetype \n' +
            '| parse /("source\\\\?":\\\\?")(?<source>[a-zA-Z0-9]+)/ \n' +
            '| parse /(?<timestamp>(\\d\\d\\d\\d-\\d\\d-\\d\\d \\d\\d:\\d\\d:\\d\\d,\\d\\d\\d))/ \n' +
            "#| filter source not in ['LoanOfferExpirationLambda', 'LoanScratchNotificationLambda', 'LoanScratchSmsNotificationLambda', 'OfferLockExpirationScheduledLambda'] \n" +
            "#| filter source in ['LoanApplicationLambda'] \n" +
            '| sort timestamp desc'
    },
    "logGroupName": {
        label: 'Log Group',
        initValue: '/aws/lambda/LoggerSubscriptionLambda'
    },
    "startTime": {
        label: 'Start Time',
        initValue: null,
        type: 'date-time'
    },
    "endTime": {
        label: 'End Time',
        initValue: null,
        type: 'date-time'
    },
    "queryId": {
        label: 'Query ID',
        initValue: '',
    },
    "limit": {
        label: 'Limit',
        initValue: 100
    }
}

const fieldList = ["startTime", "endTime", "logGroupName", "limit", "queryId", "region", "production"];

const requestType = 'cloudwatch-insights-query'
const recentType = 'cloudwatch-recent-queries'
const logGroupsType= 'cloudwatch-log-groups'
const dateFns = new DateFnsUtils();

const warnTimeRange = 10 * 24 * 60 * 60 * 1000; // 10 days

const formatDate = (date) => {
    return dateFns.format(date, "yyyy-MM-dd HH:mm:ss");
}

const LogEvent = ({row, onDateClick}) => {
    const [expanded, setExpanded] = React.useState(true);
    const color = row.event && row.event.includes('ERROR:') ? 'pink' : 'lavender'
    const date = formatDate(new Date(row["@timestamp"] + 'Z'));
    let source = row.source;
    let event = row.event;
    if (event) {
        if (event.startsWith("{")) {
            const match = /^.*sourcetype[^}]*"}/.exec(event);
            if (match && match.length === 1) {
                const ev = match[0].replaceAll('\\"', '"').replaceAll('\\\\', '\\');
                try {
                    const wrappedEvent = JSON.parse(ev)
                    event = wrappedEvent.event;
                    source = wrappedEvent.source;
                } catch (e) {
                    event = ev
                }
            }
        } else {
            event = event.replaceAll('\\n','\n').replaceAll('\\u0027','\u0027').replaceAll('\\u003d','\u003d');
        }
    } else if (row["@message"]) {
        event = row["@message"];
    }

    return (
        <div style={{borderBottom: "1px solid #A9A9A9"}}>
            <div style={{background: color, display: "flex", alignItems: "center"}}>
                <Chip label={date} variant="default" size="small" style={{marginRight: "5px"}} onClick={() => onDateClick(date)}/>
                {source && <Chip label={source} variant="default" size="small" color="primary"/>}
                <div style={{marginLeft: "auto", marginRight: "10px"}} onClick={() => setExpanded(!expanded)}>
                    { expanded ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                </div>
            </div>
            { expanded && event &&
                <Editor
                    value={event}
                    onValueChange={(code) => {}}
                    highlight={(code) => highlight(code, languages.json, 'json')}
                    style={{
                        fontFamily: '"Fira code", "Fira Mono", monospace',
                        fontSize: 12.5,
                    }}
                />
            }
        </div>
    );
}

const InsightsQueryResponse = ({jsonView, insightsQueryResponse, onDateClick}) => {
    const [expanded, setExpanded] = React.useState(true);
    if (insightsQueryResponse) {
        if (jsonView) {
            return <JsonView data={insightsQueryResponse} shouldExpandNode={(keyPath, data, level) => level < 3}/>
        }
        let cost = 0;
        if (insightsQueryResponse.statistics && insightsQueryResponse.statistics.bytesScanned) {
            cost = (insightsQueryResponse.statistics.bytesScanned / 1e+9 * 0.005).toFixed(4)
        }
        return (
            <div>
                <div style={{padding: "3px", marginBottom: "5px", background: "yellowgreen", display: "flex", alignItems: "center"}}>
                    <div style={{marginLeft: "10px", color: "white"}}>
                        Statistics
                    </div>
                    <div style={{marginLeft: "5px"}}>
                        fetched: <span style={{color: "white"}}>{insightsQueryResponse.results.length}</span>
                    </div>
                    <div style={{marginLeft: "5px"}}>
                        matched: <span style={{color: "white"}}>{insightsQueryResponse.statistics.recordsMatched}</span>
                    </div>
                    <div style={{marginLeft: "5px"}}>
                        scanned: <span style={{color: "white"}}>{insightsQueryResponse.statistics.recordsScanned}</span>
                    </div>
                    <div style={{marginLeft: "5px"}}>
                        [ <span style={{color: "yellow"}}>{formatDate(insightsQueryResponse.statistics.startTime)} - {formatDate(insightsQueryResponse.statistics.endTime)}</span> ]
                        [ <span style={{color: "white"}}>{insightsQueryResponse.statistics.production ? 'wisetack' : insightsQueryResponse.statistics.profile}</span> ]
                        [ <span style={{color: "white"}}>{insightsQueryResponse.statistics.region}</span> ]
                        [ <span style={{color: "white"}}>${cost}</span> ]
                    </div>
                    <div style={{marginLeft: "auto", marginRight: "10px"}} onClick={() => setExpanded(!expanded)}>
                        { expanded ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                    </div>
                </div>
                {expanded && insightsQueryResponse.results.map((row) => (
                    <LogEvent key={row["@ptr"]} row={row} onDateClick={onDateClick}/>
                ))}
            </div>
        )
    }
    return null;
}

const Query = ({query, onClick}) => {
    const [expanded, setExpanded] = React.useState(false);
    return (
        <div style={{borderBottom: "1px solid #A9A9A9"}}>
            <div style={{background: 'lavender', display: "flex", alignItems: "center"}}>
                <Chip label={query.queryId} variant="default" size="small" style={{marginRight: "5px"}} onClick={() => onClick(query)}/>
                <div style={{marginRight: "5px", color: "green"}}>{formatDate(new Date(query.createTime))}</div>
                <div style={{marginRight: "5px"}}>{query.status}</div>
                <div style={{marginLeft: "auto", marginRight: "10px"}} onClick={() => setExpanded(!expanded)}>
                    { expanded ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                </div>
            </div>
            { expanded && query.queryString &&
                <Editor
                    value={query.queryString}
                    onValueChange={(code) => {}}
                    highlight={(code) => highlight(code, languages.json, 'json')}
                    style={{
                        fontFamily: '"Fira code", "Fira Mono", monospace',
                        fontSize: 12.5,
                        marginTop: "5px",
                        marginBottom: "5px"
                    }}
                />
            }
        </div>
    )
}

const LogGroupsList = ({jsonView, logGroupsResponse, onLogGroupClick}) => {
    const [expanded, setExpanded] = React.useState(true);

    const creationTimeRenderer = ({value}) => {
        if (!value) {
            return null;
        }
        return formatUT(value)
    }

    const logGroupNameRenderer = ({value}) => {
        if (!value) {
            return null;
        }
        return <Link onClick={() => onLogGroupClick(value)} style={{cursor: "pointer"}}>
            {value}
        </Link>
    }

    const columns = [
        {field: 'logGroupName', headerName: 'Name', width: 450, renderCell: logGroupNameRenderer},
        {field: 'creationTime', headerName: 'Created', width: 200, renderCell: creationTimeRenderer},
        {field: 'storedBytes', headerName: 'Stored Bytes', width: 250}
    ]

    if (logGroupsResponse && logGroupsResponse.logGroups) {
        if (jsonView) {
            return <JsonView data={logGroupsResponse} shouldExpandNode={(keyPath, data, level) => level < 3}/>
        }
        return (
            <div style={{borderTop: "1px solid #A9A9A9", marginBottom: "10px"}}>
                <div style={{background: "yellowgreen", display: "flex", alignItems: "center"}}>
                    <div style={{marginLeft: "10px", color: "white"}}>
                        Log Groups
                    </div>
                    <div style={{marginLeft: "auto", marginRight: "10px"}} onClick={() => setExpanded(!expanded)}>
                        { expanded ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                    </div>
                </div>
                {expanded && <DataGrid
                    rows={logGroupsResponse.logGroups}
                    autoHeight={true}
                    rowHeight={40}
                    pageSize={20}
                    columns={columns}
                    getRowId={(row) => row.logGroupName}
                    hideFooterSelectedRowCount={true}
                    density='compact'
                />}
            </div>
        )
    }
    return null;
}

const QueriesList = ({jsonView, insightsQueriesResponse, onQueryClick}) => {
    const [expanded, setExpanded] = React.useState(true);
    if (insightsQueriesResponse) {
        if (jsonView) {
            return <JsonView data={insightsQueriesResponse} shouldExpandNode={(keyPath, data, level) => level < 3}/>
        }
        return (
            <div style={{borderTop: "1px solid #A9A9A9", marginBottom: "10px"}}>
                <div style={{background: "yellowgreen", display: "flex", alignItems: "center"}}>
                    <div style={{marginLeft: "10px", color: "white"}}>
                        Queries List
                    </div>
                    <div style={{marginLeft: "auto", marginRight: "10px"}} onClick={() => setExpanded(!expanded)}>
                        { expanded ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                    </div>
                </div>
                {expanded && insightsQueriesResponse.queries.map((query) => (
                    <Query key={query.queryId} query={query} onClick={onQueryClick}/>
                ))}
            </div>
        )
    }
    return null;
}

export default function CloudWatchLogsPanel({submitRequest}) {
    const insightsQueryResponse = useSelector(state => state.console.insightsQueryResponse);
    const insightsQueriesResponse = useSelector(state => state.console.insightsQueriesResponse);
    const logGroupsResponse = useSelector(state => state.console.logGroupsResponse);
    const formsData = useSelector(state => state.console.formsData[requestType]) || {};
    if (!formsData.endTime || !formsData.startTime) {
        const now = Date.now();
        formsData.endTime = new Date(now);
        formsData.startTime = new Date(now - 60 * 15 * 1000);
    }
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();
    const [fields, handleOnChange] = useFields(formFields, formsData, fieldList);
    const [jsonView, setJsonView] = React.useState(false);

    const handleRequest = () => {
        submitRequest(requestType, fields, newRequest());
    }

    const handleLogGroups = (logGroupNamePrefix) => {
        submitRequest(logGroupsType, {
            production: fields.production,
            region: fields.region,
            logGroupNamePrefix
        }, newRequest());
    }
    const handleRecent = () => {
        submitRequest(recentType, fields, newRequest());
    }

    const handleSearchChange = (queryString) => {
        handleOnChange({target: {value: queryString, name: "queryString"}})
    }

    const handleOnPeriodClick = (seconds) => {
        const now = Date.now();
        handleOnChange({target: {value: new Date(now), name: "endTime"}})
        handleOnChange({target: {value: new Date(now - seconds * 1000), name: "startTime"}})
    }

    const handleOnEventDateClick = (date) => {
        const baseDate = new Date(date).getTime();
        handleOnChange({target: {value: new Date(baseDate - 1000), name: "startTime"}})
        handleOnChange({target: {value: new Date(baseDate + 1000), name: "endTime"}})
    }

    const handleOnLogGroupClick = (logGroupName) => {
        handleOnChange({target: {value: logGroupName, name: "logGroupName"}})
        handleOnChange({target: {value: "fields @timestamp, @message", name: "queryString"}})
    }

    const handleOnQueryClick = (query) => {
        if (query.queryId) {
            handleOnChange({target: {value: query.queryId, name: "queryId"}})
        }
        if (query.queryString) {
            const qp = query.queryString.split('|');
            if (qp.length > 0) {
                let result = qp[0].match(/SOURCE "(.*?)" START=(\d+) END=(\d+)/)
                if (result && result.length === 4) {
                    const source = result[1]
                    const start = result[2]
                    const end = result[3]
                    handleOnChange({target: {value: source, name: "logGroupName"}})
                    handleOnChange({target: {value: new Date(start * 1000), name: "startTime"}})
                    handleOnChange({target: {value: new Date(end * 1000), name: "endTime"}})
                    qp.shift();
                }
                const lastItem = qp[qp.length - 1]
                result = lastItem.match(/^ limit (\d+)$/)
                if (result && result.length === 2) {
                    handleOnChange({target: {value: parseInt(result[1]), name: "limit"}})
                    qp.pop();
                }
                handleOnChange({target: {value: qp.join('|'), name: "queryString"}})
            }
        }
    }

    const Warning = () => {
        let warning = null;
        if (fields["startTime"] && fields["endTime"] && fields["endTime"].getTime() - fields["startTime"].getTime() > warnTimeRange) {
            warning = "Selected time range is more than 10 days. Please take into account that logs Insights queries cost is $0.005 per GB of data scanned."
        }
        if (warning) {
            return <Alert severity="warning">{warning}</Alert>
        }
        return null;
    }

    const panelTitle = fields["production"] ? <span>CloudWatch Logs <span style={{color: "red"}}>(Production)</span></span> : "CloudWatch Logs"

    return (
        <ExPanel title={panelTitle}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid item xs={12}>
                    <div>
                        <Chip onClick={() => handleOnPeriodClick(15 * 60)} label="15m" variant="default" size="small" style={{marginRight: "4px"}}/>
                        <Chip onClick={() => handleOnPeriodClick(30 * 60)} label="30m" variant="default" size="small" style={{marginRight: "4px"}}/>
                        <Chip onClick={() => handleOnPeriodClick(60 * 60)} label="1h" variant="default" size="small" style={{marginRight: "4px"}}/>
                        <Chip onClick={() => handleOnPeriodClick(60 * 60 * 3)} label="3h" variant="default" size="small" style={{marginRight: "4px"}}/>
                        <Chip onClick={() => handleOnPeriodClick(60 * 60 * 12)} label="12h" variant="default" size="small" style={{marginRight: "4px"}}/>
                        <Chip onClick={() => handleOnPeriodClick(60 * 60 * 24)} label="24h" variant="default" size="small" />
                    </div>
                    <form>
                        {fieldList.map((name) => (
                            <FormInput
                                disabled={requestInProgress}
                                key={name}
                                name={name}
                                onChange={handleOnChange}
                                getEditValue={name => fields[name]}
                                formFields={formFields}
                            />
                        ))}
                    </form>
                    <Editor
                        value={fields["queryString"]}
                        onValueChange={(code) => handleSearchChange(code)}
                        highlight={(code) => highlight(code, languages.sql, 'sql')}
                        padding={10}
                        style={{
                            fontFamily: '"Fira code", "Fira Mono", monospace',
                            fontSize: 14,
                            marginBottom: '20px',
                            marginTop: '20px'
                        }}
                    />
                    <Warning />
                    <DialogActions>
                        <Button disabled={requestInProgress} variant="contained" color="default" onClick={() => handleLogGroups('API-Gateway-')}>API Gateway Log Groups</Button>
                        <Button disabled={requestInProgress} variant="contained" color="default" onClick={() => handleLogGroups()}>All Log Groups</Button>
                        <Button disabled={requestInProgress} variant="contained" color="default" onClick={handleRecent}>Recent Queries</Button>
                        <Button disabled={requestInProgress} variant="contained" color="primary" onClick={handleRequest}>{fields["production"] ? "Submit query to production" : "Submit query"}</Button>
                    </DialogActions>
                    <ErrorMessage errorMessage={requestError}/>
                    <Progress show={requestInProgress} requestId={requestId}/>
                    <JsonSwitch hide={!insightsQueryResponse && !insightsQueriesResponse && !logGroupsResponse.logGroups} jsonView={jsonView} setJsonView={setJsonView}/>
                    <LogGroupsList
                        jsonView={jsonView}
                        logGroupsResponse={logGroupsResponse}
                        onLogGroupClick={handleOnLogGroupClick}
                    />
                    <QueriesList
                        jsonView={jsonView}
                        insightsQueriesResponse={insightsQueriesResponse}
                        onQueryClick={handleOnQueryClick}
                    />
                    <InsightsQueryResponse
                        jsonView={jsonView}
                        insightsQueryResponse={insightsQueryResponse}
                        onDateClick={handleOnEventDateClick}
                    />
                </Grid>
            </MuiPickersUtilsProvider>
        </ExPanel>
    )
}