import React from "react";

import Alert from "./Alert";

export default function ErrorMessage({errorMessage}) {
    if (errorMessage) {
        return <Alert severity="error">{errorMessage}</Alert>
    }
    return null;
}
