import React from "react";
import {useSelector} from "react-redux";

import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import {Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

import DraggableDialog from "./DraggableDialog";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import JsonView from "./JsonView";
import ExPanel from "./ExPanel";
import useApiRequest from "../hooks/useApiRequest";
import Avatar from "@material-ui/core/Avatar";

export default function SignupDialog({data, onClose, submitRequest, parentRequestId, openSignup, createLegacySignup}) {
    const signupInfo = useSelector(state => state.console.signupInfo);
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest(parentRequestId);
    const dataPresent = !!signupInfo && !!data && data.signupId === signupInfo.signupId;

    const handleOnReload = () => {
        if (data && data.signupId) {
            submitRequest({signupId: data.signupId}, newRequest());
        }
    }

    React.useEffect(() => {
        handleOnReload();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOpenSignupLink = () => {
        if (data.signupId) {
            openSignup(data.signupId, true);
        }
    }

    const handleCreateSignup = () => {
        if (data.id) {
            createLegacySignup({merchantId: data.id}, newRequest())
        }
    }

    const OpenSignupButton = () => {
        if (!dataPresent || !openSignup) {
            return null;
        }
        return (
            <Button variant="outlined" color="primary" style={{marginRight: "10px"}} size="small" onClick={handleOpenSignupLink}>
                Open Signup Link
            </Button>
        )
    }

    const CreateSignupButton = () => {
        if (dataPresent || requestInProgress) {
            return null
        }
        return (
            <>
                <Button variant="outlined" color="primary" style={{marginBottom: "10px"}} size="small" onClick={handleCreateSignup}>
                    Create Legacy Signup
                </Button>
                <div style={{marginBottom: "10px"}}>
                    Please reload merchant list to see signup data after pressing this button.
                </div>
            </>
        )
    }

    return (
        <Dialog
            PaperComponent={DraggableDialog}
            aria-labelledby="draggable-dialog-title"
            open={true}
            maxWidth="md"
            fullWidth={true}
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                Signup <CopyToClipboard text={data.signupId}>
                <Chip label={data.signupId} size="small" color="primary" avatar={<Avatar>S</Avatar>} onClick={handleOnReload}/>
            </CopyToClipboard>
            </DialogTitle>
            <DialogContent dividers={true}>
                <OpenSignupButton/>
                <CreateSignupButton/>
                <JsonView hide={!dataPresent} data={signupInfo} shouldExpandNode={(keyPath, data, level) => level < 1}/>
                <ExPanel title="Merchant Data">
                    <Grid item xs={12}>
                        <JsonView data={data} shouldExpandNode={(keyPath, data, level) => level < 1}/>
                    </Grid>
                </ExPanel>
            </DialogContent>
            <div style={{paddingTop: "10px", paddingLeft: "10px", paddingRight: "10px", paddingBottom: "5px"}}>
                <ErrorMessage errorMessage={requestError}/>
                <Progress show={requestInProgress} requestId={requestId}/>
            </div>
            <DialogActions>
                <Button disabled={requestInProgress} variant="contained" onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}
