import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import Tooltip from "@material-ui/core/Tooltip";

export default function Progress({show, requestId}) {
    if (!requestId) {
        requestId = '-'
    }
    if (show) {
        return <Tooltip title={requestId}><LinearProgress/></Tooltip>
    }
    return null;
}
