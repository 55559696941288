import React from "react";
import {makeStyles} from "@material-ui/core";

import {KeyboardDatePicker, KeyboardDateTimePicker} from "@material-ui/pickers";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import PartnerSelect from "./PartnerSelect";
import SimpleSelect from "./SimpleSelect";
import {PhoneTextField} from "./PhoneTextField";

const useStyles = makeStyles((theme) => ({
    updated: props => ({
        '& input': {
            color: props.updated ? theme.palette.primary.main : theme.palette.text.primary
        },
        color: props.updated ? theme.palette.primary.main : theme.palette.text.primary
    })
}))

export default function FormInput({name, formFields, getEditValue, onChange, disabled, updated, error}) {
    const classes = useStyles({updated})
    if(formFields[name].type === 'hidden') {
        return null;
    }
    if(formFields[name].type === 'date') {
        return <KeyboardDatePicker
            style={{marginRight: "10px"}}
            className={classes.updated}
            label={formFields[name].label}
            disabled={disabled || formFields[name].disabled}
            disableToolbar
            variant="inline"
            inputVariant="outlined"
            autoOk
            format="MM/dd/yyyy"
            margin="normal"
            size="small"
            value={getEditValue(name)}
            onChange={(value) => onChange({target: {value, name}})}
            {...(error && {error:true,helperText:error})}
        />
    }
    if(formFields[name].type === 'date-time') {
        return <KeyboardDateTimePicker
            style={{marginRight: "10px"}}
            className={classes.updated}
            label={formFields[name].label}
            disabled={disabled || formFields[name].disabled}
            disableToolbar
            variant="inline"
            inputVariant="outlined"
            autoOk
            format="MM/dd/yyyy HH:mm:ss"
            ampm={false}
            margin="normal"
            size="small"
            value={getEditValue(name)}
            onChange={(value) => onChange({target: {value, name}})}
            {...(error && {error:true,helperText:error})}
        />
    }
    if(formFields[name].type === 'switch') {
        const value = getEditValue(name);
        return <FormControlLabel
            control={
                <Switch
                    disabled={disabled || formFields[name].disabled}
                    checked={value}
                    onChange={() => onChange({target: {value: !value, name}})}
                    color="primary"
                />
            }
            label={<span className={classes.updated}>{formFields[name].label}</span>}
        />
    }
    if(formFields[name].type === 'select') {
        return <SimpleSelect
            name={name}
            updated={updated}
            disabled={disabled || formFields[name].disabled}
            value={getEditValue(name)}
            multiple={formFields[name].multiple}
            label={formFields[name].label}
            onChange={onChange}
            data={formFields[name].data}
            error={error}
        />
    }
    if(formFields[name].type === 'partner-select') {
        return <PartnerSelect
            name={name}
            value={getEditValue(name)}
            label={formFields[name].label}
            onChange={onChange}
            submitPartnersInfoRequest={formFields[name].dataRequest}
        />
    }
    if(formFields[name].type === 'phone') {
        return <PhoneTextField
            name={name}
            className={classes.updated}
            label={formFields[name].label}
            disabled={disabled || formFields[name].disabled}
            variant="outlined"
            margin="normal"
            size="small"
            fullWidth
            value={getEditValue(name)}
            onChange={onChange}
            {...(error && {error:true,helperText:error})}
        />
    }
    return <TextField
        name={name}
        className={classes.updated}
        label={formFields[name].label}
        type={formFields[name].type}
        disabled={disabled || formFields[name].disabled}
        variant="outlined"
        margin="normal"
        size="small"
        fullWidth
        value={getEditValue(name)}
        onChange={onChange}
        {...(error && {error:true,helperText:error})}
    />
}