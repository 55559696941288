import {randomPhoneNumber} from "./format";

export const phoneInputInterceptor = (e) => {
    if (e.nativeEvent.data === '~') {
        return {target: {value: randomPhoneNumber()}}
    }
    if (e.nativeEvent.data === '%') {
        return {target: {value: '+1-555-555-5555'}}
    }
    if (e.nativeEvent.data === '!') {
        return {target: {value: ''}}
    }
    return e;
}
