import React from "react";
import {useSelector} from "react-redux";

import Grid from "@material-ui/core/Grid";
import {Button, DialogActions} from "@material-ui/core";

import ExPanel from "./ExPanel";
import FormInput from "./FormInput";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import Alert from "./Alert";
import {partnerFormFieldList, partnerFormFields} from "../utils/constants";
import useApiRequest from "../hooks/useApiRequest";
import useFields from "../hooks/useFields";
import useSettings from "../hooks/useSettings";
import ProductPlanList from "./ProductPlanList";
import useProductPlans from "../hooks/useProductPlans";

const formFields = {
    ...partnerFormFields,
    "sandbox": {
        label: 'Sandbox',
        type: 'switch',
        initValue: false,
    }
};

const fieldList = [
    ...partnerFormFieldList,
    "sandbox"
];

const requestType = 'create-partner';

export default function CreatePartnerPanel({submitRequest}) {
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();
    const formsData = useSelector(state => state.console.formsData[requestType]);
    const createPartnerRequestId = useSelector(state => state.console.createPartnerRequestId);

    const {supportedPlans, handlePlanAdd, handlePlanUpdate, handlePlanRemove, handlePlanUndo} = useProductPlans({})

    useSettings(formFields)
    const [fields, handleOnChange, handleOnClean] = useFields(formFields, formsData);

    const handleRequest = () => {
        submitRequest(requestType, {...fields, supportedPlans}, newRequest());
    }

    const PartnerCreated = () => {
        if (createPartnerRequestId && requestId && createPartnerRequestId === requestId) {
            return <Alert severity="success">Partner created</Alert>
        }
        return null;
    }

    return (
        <ExPanel title="Create New Partner">
            <Grid item xs={12}>
                <form>
                    {fieldList.map((name) => (
                        <FormInput
                            key={name}
                            name={name}
                            onChange={handleOnChange}
                            getEditValue={name => fields[name]}
                            formFields={formFields}
                        />
                    ))}
                </form>
                <ProductPlanList
                    supportedPlans={supportedPlans}
                    onRemove={handlePlanRemove}
                    onAdd={handlePlanAdd}
                    onUpdate={handlePlanUpdate}
                    onUndo={handlePlanUndo}
                />
                <ErrorMessage errorMessage={requestError}/>
                <Progress show={requestInProgress}/>
                <PartnerCreated/>
                <DialogActions>
                    <Button disabled={requestInProgress} variant="contained" color="primary" onClick={handleOnClean}>Clean</Button>
                    <Button disabled={requestInProgress} variant="contained" color="primary" onClick={handleRequest}>Submit</Button>
                </DialogActions>
            </Grid>
        </ExPanel>
    )
}