import React from 'react';
import {useSelector} from "react-redux";
import { v4 as uuid } from 'uuid';

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";

export default function PartnerSelect({label, value, name, onChange, submitPartnersInfoRequest}) {
    const apiRequests = useSelector(state => state.console.apiRequests);
    const apiError = useSelector(state => state.console.apiError);
    const partnersInfo = useSelector(state => state.console.partnersInfo);

    const [requestId, setRequestId] = React.useState('');
    const dataAvailable = !!partnersInfo && !!partnersInfo.accounts && partnersInfo.accounts.length > 0;
    const defaultValue = dataAvailable ? partnersInfo.accounts[0].id : '';

    React.useEffect(() => {
        if (!dataAvailable && submitPartnersInfoRequest) {
            const id = uuid();
            setRequestId(id);
            submitPartnersInfoRequest({}, id);
        }
    }, [dataAvailable, submitPartnersInfoRequest]);

    React.useEffect(() => {
        if (!value && defaultValue) {
            onChange({target: {name: name, value: defaultValue}});
        }
    }, [value, defaultValue, name, onChange]);

    return <FormControl variant="outlined" fullWidth margin="normal">
        <InputLabel id="select-account-label" margin="dense">{label}</InputLabel>
        {dataAvailable && <Select
            name={name}
            labelId="select-account-label"
            id="select-account"
            value={value || defaultValue}
            margin="dense"
            onChange={onChange}
            label={label}
        >
            {
                partnersInfo.accounts.map((item) => (
                    <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                ))
            }
        </Select>}
        <ErrorMessage apiError={apiError} requestId={requestId}/>
        <Progress apiRequests={apiRequests} requestId={requestId}/>
    </FormControl>
}
