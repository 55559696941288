import React from 'react';
import {connect} from "react-redux";

import AppLayout from "../components/AppLayout";
import UsersInfoPanel from "../components/UsersInfoPanel";
import CreateUserPanel from "../components/CreateUserPanel";
import MerchantPortalPanel from "../components/MerchantPortalPanel";
import { submitIpcRequest } from "../store/actions/consoleActions";
import UsersLockoutPanel from "../components/UsersLockoutPanel";

function UsersPage(props) {
    return (
        <AppLayout title="Users">
            <CreateUserPanel submitRequest={props.submitIpcRequest}/>
            <UsersInfoPanel submitRequest={props.submitIpcRequest}/>
            <UsersLockoutPanel submitRequest={props.submitIpcRequest}/>
            <MerchantPortalPanel submitRequest={props.submitIpcRequest}/>
        </AppLayout>
    )
}

export default connect(null, {submitIpcRequest})(
    UsersPage
);
