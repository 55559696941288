import React from 'react';
import {useSelector} from "react-redux";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

import ExPanel from "./ExPanel";
import SubmitButton from "./SubmitButton";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import useApiRequest from "../hooks/useApiRequest";

export default function EncryptPanel({encryptWithVault}) {
    const vaultToken = useSelector(state => state.console.vaultToken);
    const encryptedValue = useSelector(state => state.console.valueToEncrypt);
    const [valueToEncrypt, setValueToEncrypt] = React.useState('');
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();

    const handleEncrypt = () => {
        if (valueToEncrypt) {
            encryptWithVault(valueToEncrypt, newRequest());
        }
    }

    const VaultToken = () => {
        if (vaultToken && !requestInProgress && encryptedValue === valueToEncrypt) {
            return (
                <TextField
                    label="Vault token"
                    variant="outlined"
                    margin="normal"
                    size="small"
                    fullWidth
                    value={vaultToken}
                />
            )
        }
        return null;
    }

    return (
        <ExPanel title="Encrypt">
            <Grid item xs={12}>
                <form>
                    <TextField
                        label="Value to encrypt"
                        variant="outlined"
                        margin="normal"
                        size="small"
                        required
                        fullWidth
                        value={valueToEncrypt}
                        onChange={e => setValueToEncrypt(e.target.value)}
                    />
                    <SubmitButton onClick={handleEncrypt} />
                </form>
                <ErrorMessage errorMessage={requestError}/>
                <Progress show={requestInProgress} requestId={requestId}/>
                <VaultToken />
            </Grid>
        </ExPanel>
    )
}
