import { v4 as uuid } from 'uuid';
import log from 'loglevel';
import {get} from "lodash-es"

import {invokeRequest} from "./requests";

log.setDefaultLevel('DEBUG')

export const encryptWithVault = (valueToEncrypt, requestId) => async (dispatch, getState) => {
    await submitRequest('vault-encrypt', valueToEncrypt, requestId, dispatch, getState, (response) => ({
        valueToEncrypt,
        vaultToken: response
    }))
}

export const decryptWithVault = (tokenToDecrypt, requestId) => async (dispatch, getState) => {
    await submitRequest('vault-decrypt', tokenToDecrypt, requestId, dispatch, getState, (response) => ({
        tokenToDecrypt,
        decryptedValue: response
    }))
}

export const submitSettings = (data, requestId) => async (dispatch, getState) => {
    await submitRequest('submit-settings', data, requestId, dispatch, getState)
}

export const getSettings = (requestId, callback) => async (dispatch, getState) => {
    await submitRequest('get-settings', null, requestId, dispatch, getState, callback)
}

export const openPaymentLink = (data) => async (dispatch) => {
    await submitRequest('open-payment-link', data, null, dispatch)
}

export const openPrequalLink = (data) => async (dispatch) => {
    await submitRequest('open-prequal-link', data, null, dispatch)
}

export const openSignup = (signupLink, createUrl) => async (dispatch) => {
    await submitRequest('open-signup', {signupLink, createUrl}, null, dispatch)
}

export const submitDataLakeRequest = (requestType, requestData, requestId) => async (dispatch, getState) => {
    await submitRequest(requestType + "-data-lake", requestData, requestId, dispatch, getState);
}

export const getAuthToken = (entityId, requestId) => async (dispatch, getState) => {
    if (entityId && entityId.startsWith('ws_')) {
        await submitRequest('get-partner-access-token', entityId, requestId, dispatch, getState);
    } else {
        await submitRequest('get-merchant-access-token', entityId, requestId, dispatch, getState);
    }
}

export const submitIpcRequest = (requestType, requestData, requestId) => async (dispatch, getState) => {
    if (requestType) {
        await submitRequest(requestType, requestData, requestId, dispatch, getState);
    } else if (requestData && requestData.requestTypeList) {
        await Promise.all(requestData.requestTypeList.map(
            item => submitRequest(item, {}, requestId, dispatch, getState)
        ))
    }
}

const submitRequest = async (requestType, requestData, requestId, dispatch, getState, responseTransformer) => {
    try {
        if (!requestId) {
            requestId = uuid();
        }
        if (!requestData) {
            requestData = {}
        }
        let requestStarted = false
        if (getState) {
            // check same request type already running to prevent duplicate requests
            const runningRequests = getState().console.apiRequests
            if (runningRequests && Object.values(runningRequests).includes(requestType)) {
                log.debug(`Preventing to run duplicate request [${requestType}].`)
                return;
            }
            const cognitoSignIn = getState().console.cognitoSignIn
            if (requestType !== 'refresh-cognito-session' && cognitoSignIn) {
                // check Cognito credentials expired
                const minutes = 5;
                const expireTime = get(cognitoSignIn, 'credentials.expireTime');
                if (expireTime && expireTime < new Date(new Date().getTime() + minutes*60000)) {
                    log.debug(`Cognito credentials about to expire: ${cognitoSignIn.credentials.expireTime}`)
                    const email = get(cognitoSignIn, 'idToken.payload.email');
                    const refreshToken = get(cognitoSignIn, 'refreshToken.token');
                    if (email && refreshToken) {
                        requestStarted = true;
                        dispatch({type: 'api-request-started', payload: {requestType, requestData, requestId}})
                        await submitRequest('refresh-cognito-session', {email, refreshToken}, null, dispatch, getState)
                    }
                }
            }
        }
        if (!requestStarted) {
            dispatch({type: 'api-request-started', payload: {requestType, requestData, requestId}})
        }
        let response = await invokeRequest(requestType, requestData);
        if (responseTransformer) {
            response = responseTransformer(response)
        }
        dispatch({type: requestType, payload: {response, requestId}})
    } catch (error) {
        if (error.code === 'ExpiredTokenException') {
            dispatch({type: 'security-token-expired', payload: true})
        }
        dispatch({type: 'api-error', payload: {error, requestId}})
    } finally {
        dispatch({type: 'api-request-finished', payload: {requestId}})
    }
}
