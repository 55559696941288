import React from "react";
import {useSelector} from "react-redux";
import {isArray} from "lodash-es";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-sql";
import "prismjs/components/prism-json";
import "prism-themes/themes/prism-material-light.css";
import DateFnsUtils from "@date-io/date-fns";

import Grid from "@material-ui/core/Grid";
import {Button, DialogActions, Chip} from "@material-ui/core";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import ExPanel from "./ExPanel";
import FormInput from "./FormInput";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import useApiRequest from "../hooks/useApiRequest";
import useFields from "../hooks/useFields";
import Editor from "react-simple-code-editor";
import JsonView from "./JsonView";
import JsonSwitch from "./JsonSwitch";

const formFields = {
    "search": {
        label: 'Search query',
        initValue: 'host="wisetack" error | head 10 | sort -date_time'
    },
}

const fieldList = []; // TODO time range

const requestType = 'splunk-search'
const dateFns = new DateFnsUtils();

const SplunkEvent = ({event}) => {
    const [expanded, setExpanded] = React.useState(true);
    const color = event._raw.includes('ERROR:') ? 'pink' : 'lavender'
    const source = isArray(event.source) ? event.source[event.source.length - 1] : event.source;
    const region = isArray(event.sourcetype) ? event.sourcetype[event.sourcetype.length - 1] : event.sourcetype;
    const date =  dateFns.format(dateFns.date(event._time), "yyyy-MM-dd HH:mm:ss");
    return <div style={{borderBottom: "1px solid #A9A9A9"}}>
        <div style={{background: color, display: "flex", alignItems: "center"}}>
            <Chip label={date} variant="default" size="small" style={{marginRight: "5px"}} />
            <Chip label={region} variant="default" size="small"  color="primary" style={{marginRight: "5px"}} />
            <Chip label={source} variant="default" size="small" />
            <div style={{marginLeft: "auto", marginRight: "10px"}} onClick={() => setExpanded(!expanded)}>
                { expanded ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
            </div>
        </div>
        { expanded &&
            <Editor
                value={event._raw}
                onValueChange={(code) => {}}
                highlight={(code) => highlight(code, languages.json, 'json')}
                style={{
                    fontFamily: '"Fira code", "Fira Mono", monospace',
                    fontSize: 12,
                }}
            />
        }
    </div>
}

const SplunkResponse = ({jsonView, splunkSearchResponse}) => {
    if (splunkSearchResponse) {
        if (jsonView) {
            return <JsonView data={splunkSearchResponse} shouldExpandNode={(keyPath, data, level) => level < 2}/>
        }
        return (
            <div>
                {splunkSearchResponse.map((row) => (
                    row.result ? <SplunkEvent key={row.result._cd} event={row.result}/> : null
                ))}
            </div>
        )
    }
    return null;
}

export default function SplunkSearchPanel({submitRequest}) {
    const splunkSearchResponse = useSelector(state => state.console.splunkSearchResponse);
    const formsData = useSelector(state => state.console.formsData[requestType]);
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();
    const [fields, handleOnChange] = useFields(formFields, formsData, fieldList);
    const [jsonView, setJsonView] = React.useState(false);

    const handleRequest = () => {
        submitRequest(requestType, fields, newRequest());
    }

    const handleSearchChange = (search) => {
        handleOnChange({target: {value: search, name: "search"}})
    }

    return (
        <ExPanel title="Splunk Search">
            <Grid item xs={12}>
                <form>
                    {fieldList.map((name) => (
                        <FormInput
                            disabled={requestInProgress}
                            key={name}
                            name={name}
                            onChange={handleOnChange}
                            getEditValue={name => fields[name]}
                            formFields={formFields}
                        />
                    ))}
                </form>
                <Editor
                    value={fields["search"]}
                    onValueChange={(code) => handleSearchChange(code)}
                    highlight={(code) => highlight(code, languages.sql, 'sql')}
                    padding={10}
                    style={{
                        fontFamily: '"Fira code", "Fira Mono", monospace',
                        fontSize: 14,
                        marginBottom: '20px',
                        marginTop: '20px'
                    }}
                />
                <DialogActions>
                    <Button disabled={requestInProgress} variant="contained" color="primary" onClick={handleRequest}>Submit</Button>
                </DialogActions>
                <ErrorMessage errorMessage={requestError}/>
                <Progress show={requestInProgress} requestId={requestId}/>
                <JsonSwitch hide={!splunkSearchResponse} jsonView={jsonView} setJsonView={setJsonView}/>
                <SplunkResponse jsonView={jsonView} splunkSearchResponse={splunkSearchResponse}/>
            </Grid>
        </ExPanel>
    )
}
