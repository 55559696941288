import React from 'react';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

export default function JsonSwitch({jsonView, setJsonView, hide}) {
    if (hide) {
        return null;
    }
    return (
        <span style={{marginLeft: "20px"}}><FormControlLabel
            control={
                <Switch
                    checked={jsonView}
                    onChange={() => setJsonView(!jsonView)}
                    color="primary"
                />
            }
            label="JSON View"
        /></span>
    )
}