import React from 'react';
import {useSelector} from "react-redux";
import {useForm} from "react-hook-form";

import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Chip from "@material-ui/core/Chip";
import EditIcon from '@material-ui/icons/Edit';

import ExPanel from "./ExPanel";
import SubmitButton from "./SubmitButton";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import JsonView from "./JsonView";
import Alert from "./Alert";
import EditUserDialog from "./EditUserDialog";
import JsonSwitch from "./JsonSwitch";
import useApiRequest from "../hooks/useApiRequest";
import {FormInputCtr} from "./FormInputCtr";
import {phoneNumberPattern} from "../utils/validators";

const formFields = {
    "merchantId": {
        label: 'Merchant ID',
        initValue: ''
    },
    "phone": {
        label: 'Phone',
        initValue: '',
        type: 'phone',
        rules: {
            pattern: phoneNumberPattern
        }
    },
    "email": {
        label: 'Email',
        initValue: ''
    },
    "limit": {
        label: 'Limit',
        type: 'number',
        initValue: 20
    }
}
const fieldList = ["merchantId", "phone", "email", "limit"];
const infoRequestType = 'users-info';

export default function UsersInfoPanel({submitRequest}) {
    const usersInfo = useSelector(state => state.console.usersInfo);
    const formsData = useSelector(state => state.console.formsData[infoRequestType]);
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();
    const [jsonView, setJsonView] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [selectedUser, setSelectedUser] = React.useState(false);
    const dataPresent = !!usersInfo && !!usersInfo.users;

    const { handleSubmit, control } = useForm({
        defaultValues: {
            ...formsData
        }
    });

    const handleInfoRequest = (fields) => {
        submitRequest(infoRequestType, fields, newRequest());
    }

    const handleEditUser = (user) => {
        setSelectedUser(user);
        setOpenDialog(true);
    }

    const handleEditUserClose = () => {
        setOpenDialog(false);
    }

    const UserRow = ({row}) => {
        const editedRow = !!selectedUser && selectedUser.userId === row.userId
        const deleteIcon = editedRow ? <EditIcon /> : null;
        const onDelete = editedRow ? () => setSelectedUser(null) : null;
        return <TableRow>
            <TableCell>
                <Chip
                    label={row.phoneNumberEncrypted}
                    size="small"
                    color={row.blocked ? "secondary" : "default"}
                    onClick={() => {handleEditUser(row)}}
                    deleteIcon={deleteIcon}
                    onDelete={onDelete}
                />
            </TableCell>
            <TableCell>{row.emailEncrypted}</TableCell>
            <TableCell>{row.merchantId}</TableCell>
            <TableCell>{row.representativeType}</TableCell>
        </TableRow>
    }

    const TableView = () => {
        if (!usersInfo.users || usersInfo.users.length === 0) {
            if (requestId && !requestError) {
                return <Alert severity="info">No users found.</Alert>
            }
            return null;
        }
        return (
            <>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Phone</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Merchant ID</TableCell>
                            <TableCell>Representative Type</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {usersInfo.users.map((row) => (
                            <UserRow key={row.merchantId + row.userId} row={row}/>
                        ))}
                    </TableBody>
                </Table>
            </>
        )
    }

    const UsersInfo = () => {
        if (!dataPresent || requestInProgress) {
            return null;
        }
        if (jsonView) {
            return <JsonView data={usersInfo} shouldExpandNode={(keyPath, data, level) => level < 3}/>
        }
        return <TableView/>
    }

    return (
        <ExPanel title="Find And Edit User">
            <Grid item xs={12}>
                <form>
                    {fieldList.map((name) => (
                        <FormInputCtr
                            key={name}
                            name={name}
                            formFields={formFields}
                            control={control}
                            disabled={requestInProgress}
                        />
                    ))}
                    <SubmitButton onClick={handleSubmit(handleInfoRequest)} disabled={requestInProgress}/>
                </form>
                <ErrorMessage errorMessage={requestError}/>
                <Progress show={requestInProgress} requestId={requestId}/>
                <JsonSwitch hide={!dataPresent} jsonView={jsonView} setJsonView={setJsonView}/>
                <UsersInfo/>
                {openDialog && <EditUserDialog
                    data={selectedUser}
                    onClose={handleEditUserClose}
                    submitRequest={submitRequest}
                />}
            </Grid>
        </ExPanel>
    )
}
