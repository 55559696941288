import React from 'react';
import {useSelector} from "react-redux";
import DateFnsUtils from '@date-io/date-fns';

import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import {Button, DialogActions, Grid} from "@material-ui/core";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import BlockIcon from '@material-ui/icons/Block';

import ExPanel from "./ExPanel";
import FormInput from "./FormInput";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import JsonSwitch from "./JsonSwitch";
import JsonView from "./JsonView";

import useApiRequest from "../hooks/useApiRequest";
import useFields from "../hooks/useFields";
import {DataGrid} from "@mui/x-data-grid";
import Chip from "@material-ui/core/Chip";

const formFields = {
    "dateTo": {
        label: 'Date',
        type: 'date',
        initValue: null
    },
    "userName": {
        label: 'User phone/IP/hash',
        initValue: ''
    },
    "limit": {
        label: 'Limit',
        type: 'number',
        initValue: 20
    }
}
const fieldList = ["dateTo", "userName", "limit"];

const UsersLockoutInfo = ({columns, loading, jsonView, usersLockoutInfo}) => {
    if (!usersLockoutInfo || !usersLockoutInfo.lockouts) {
        return null;
    }
    if (jsonView) {
        return <JsonView data={usersLockoutInfo} shouldExpandNode={(keyPath, data, level) => level < 3}/>
    }
    return <div style={{height: 400, width: '100%'}}>
        <DataGrid
            rows={usersLockoutInfo.lockouts}
            hideFooterSelectedRowCount={true}
            autoPageSize={true}
            columns={columns}
            loading={loading}
            density='compact'
        />
    </div>
}

const infoRequestType = 'users-lockout-info';
const updateRequestType = 'users-lockout-update';

export default function UsersLockoutPanel({submitRequest}) {
    const usersLockoutInfo = useSelector(state => state.console.usersLockoutInfo);
    const formsData = useSelector(state => state.console.formsData[infoRequestType]);
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();
    const [fields, handleOnChange] = useFields(formFields, formsData, fieldList);
    const [jsonView, setJsonView] = React.useState(false);
    const dataPresent = !!usersLockoutInfo;

    const handleInfoRequest = () => {
        submitRequest(infoRequestType, fields, newRequest());
    }

    const handleAction = (row, maxReached) => {
        const data = {userNameHash: row.id}
        if (maxReached) {
            data.release = true
        } else {
            data.acquire = true
        }
        submitRequest(updateRequestType, data, newRequest());
    }

    const attemptsRenderer = ({row, value}) => {
        const maxReached = row.authPinCreationAttempts >= 10 || row.authPinAttempts >= 10
        const label = `${row.authPinCreationAttempts}/${row.authPinAttempts}`
        const icon = maxReached ? <BlockIcon/> : <CheckCircleOutlineIcon/>
        return <Chip
            label={label}
            icon={icon}
            size="small"
            color={maxReached ? "secondary" : "default"}
            onClick={() => {
                handleAction(row, maxReached)
            }}
        />
    }

    const columns = [
        {field: 'id', headerName: 'Lockout ID', width: 380},
        {field: 'attempts', headerName: 'Attempts', width: 100, renderCell: attemptsRenderer, disableColumnMenu: true, sortable: false},
        {field: 'authPinCreatedAt', headerName: 'PIN Created At', width: 170},
        {field: 'authPinAttemptedAt', headerName: 'PIN Attempted At', width: 170},
        {field: 'correlationId', headerName: 'Correlation ID', width: 300},
    ]

    return (
        <ExPanel title="Users Lockout">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid item xs={12}>
                    <form>
                        {fieldList.map((name) => (
                            <FormInput
                                disabled={requestInProgress}
                                key={name}
                                name={name}
                                onChange={handleOnChange}
                                getEditValue={name => fields[name]}
                                formFields={formFields}
                            />
                        ))}
                    </form>
                    <DialogActions>
                        <Button disabled={requestInProgress} variant="contained" color="primary" onClick={handleInfoRequest}>Submit</Button>
                    </DialogActions>
                    <ErrorMessage errorMessage={requestError}/>
                    <Progress show={requestInProgress} requestId={requestId}/>
                    <JsonSwitch hide={!dataPresent} jsonView={jsonView} setJsonView={setJsonView}/>
                    <UsersLockoutInfo
                        columns={columns}
                        loading={requestInProgress}
                        usersLockoutInfo={usersLockoutInfo}
                        jsonView={jsonView}
                    />
                </Grid>
            </MuiPickersUtilsProvider>
        </ExPanel>
    )
}