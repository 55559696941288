import log from 'loglevel';

const TOKEN_KEY = 'WCon:token'
const EMAIL_KEY = 'WCon:email'

export const saveUser = (email, refreshToken) => {
    try {
        localStorage.setItem(EMAIL_KEY, email);
        localStorage.setItem(TOKEN_KEY, refreshToken);
    } catch {
        log.error(`User ${email} refresh token was not saved to the local storage.`)
    }
}

export const getUser = () => {
    try {
        const refreshToken = localStorage.getItem(TOKEN_KEY);
        const email = localStorage.getItem(EMAIL_KEY);
        if (!refreshToken || !email) {
            return undefined;
        }
        return {refreshToken, email};
    } catch (err) {
        return undefined;
    }
}

export const forgetUser = () => {
    try {
        localStorage.removeItem(TOKEN_KEY);
        localStorage.removeItem(EMAIL_KEY);
    } catch {
        // ignore write errors
    }
}

