import {format} from 'date-fns'
import {isArray, isEmpty, isNull, isObject, transform} from 'lodash-es'
import isEq from 'lodash-es/isEqual'
import {isString} from "lodash-es/lang";
import {v4 as uuid} from "uuid";

export const isJSONString = (s) => {
    if (!s || !isString(s)) {
        return false;
    }
    return s.startsWith('{') && s.endsWith('}')
}

export const formatAuditTrail = (row) => {
    if (!!row && !!row.data && isObject(row.data)) {
        for (const [key, value] of Object.entries(row.data)) {
            if (isJSONString(value)) {
                row.data[key] = JSON.parse(value)
            }
        }
    }
    return row
}

export const formatErrorMessage = (apiError, requestId) => {
    let errorMessage = '';
    if (apiError && apiError.requestId === requestId) {
        const error = apiError.error;
        if (error.message) {
            errorMessage = error.message;
        }
        if (error.response && error.response.data && error.response.data.message) {
            errorMessage = error.response.data.message;
        }
    }
    return errorMessage;
}

export const formatUT = (timestamp, fmt) => {
    if (!timestamp) {
        return timestamp
    }
    return format(new Date(timestamp), fmt || 'yyyy-MM-dd HH:mm:ss');
}

export const formatMerchantsInfo = (merchantsInfo) => {
    if (!merchantsInfo || !merchantsInfo.merchants) {
        return merchantsInfo;
    }
    merchantsInfo.merchants.forEach(item => {
        if (item.createdAt) {
            item.createdAt = formatUT(item.createdAt, 'yyyy-MM-dd HH:mm');
        }
    })
    return merchantsInfo;
}

export const formatPrequalInfo = (prequalInfo) => {
    if (!prequalInfo || !prequalInfo.prequals) {
        return prequalInfo;
    }
    prequalInfo.prequals.forEach(item => {
        formatPrequalItem(item);
    })
    return prequalInfo;
}

export const formatLockoutInfo = (lockoutInfo) => {
    if (!lockoutInfo || !lockoutInfo.lockouts) {
        return lockoutInfo
    }
    lockoutInfo.lockouts.forEach(item => {
        item.id = item.userNameHash
        delete item.userNameHash
        if (item.authPinCreatedAt) {
            item.authPinCreatedAt = formatUT(item.authPinCreatedAt);
        }
        if (item.authPinAttemptedAt) {
            item.authPinAttemptedAt = formatUT(item.authPinAttemptedAt);
        }
    })
    return lockoutInfo;
}

export const formatTransactionsInfo = (transactionsInfo) => {
    if (!transactionsInfo || !transactionsInfo.transactions) {
        return transactionsInfo;
    }
    transactionsInfo.transactions.forEach(item => {
        if (item.createdAt) {
            item.createdAt = formatUT(item.createdAt, 'yyyy-MM-dd HH:mm');
        }
    })
    return transactionsInfo;
}

export const formatLoanInfo = (loanInfo) => {
    if (!loanInfo) {
        return;
    }
    if (loanInfo.loanApplication) {
        formatLoanItem(loanInfo.loanApplication);
    }
    return loanInfo;
}

const formatPrequalItem = (item) => {
    if (!item) {
        return;
    }
    item.prequalCreatedAt = formatUT(item.prequalCreatedAt);
    formatLoanItem(item)
}

const formatLoanItem = (loan) => {
    if (!loan) {
        return
    }
    if (loan.createdAt) {
        loan.createdAt = new Date(loan.createdAt);
    }
    if (loan.serviceCompletedOn) {
        loan.serviceCompletedOn = new Date(loan.serviceCompletedOn);
    }
    if (loan.originationDate) {
        loan.originationDate = new Date(loan.originationDate);
    }
    if (loan.ruleGroupSet) {
        loan.ruleGroupSet.forEach(element => {
            if (element.updatedAt) {
                element.updatedAt = new Date(element.updatedAt);
            }
        });
    }
    if (loan.authPinCreatedAt) {
        loan.authPinCreatedAt = formatUT(loan.authPinCreatedAt)
    }
    if (loan.authPinAttemptedAt) {
        loan.authPinAttemptedAt = formatUT(loan.authPinAttemptedAt)
    }
    if (loan.lastAccessedOn) {
        loan.lastAccessedOn = formatUT(loan.lastAccessedOn)
    }
}

export const formatRecentMerchants = (merchants) => {
    merchants.sort(function (a, b) {
        return b.createdAt - a.createdAt;
    });

    merchants.forEach(item => {
        if (item.createdAt) {
            item.createdAt = formatUT(item.createdAt, 'yyyy-MM-dd');
        }
    })
    return merchants;
}

export const formatBorrowerProfile = (data) => {
    if (data.profileList) {
        data.profileList.forEach(item => {
            item.createdAtUT = formatUT(item.createdAt);
            item.canceledAt = formatUT(item.canceledAt);
        })
    }
    return data;
}

export const formatBorrowerCreditFile = (data) => {
    if (data.creditReportList) {
        data.creditReportList.forEach(item => {
            item.createdAtUT = formatUT(item.createdAt);
            item.canceledAt = formatUT(item.canceledAt);
        })
    }
    return data;
}

export const formatProductPlans = (supportedPlans) => {
    const items = {}
    for (const [vertical, plans] of Object.entries(supportedPlans)) {
        items[vertical] = plans.map(item => ({
            ...item,
            id: item.id || uuid()
        }))
    }
    return items
}

export const fieldsInitialState = (fields, formsData) => {
    const items = Object.keys(fields).map((key) => {
        return {[key]: fields[key].initValue}
    })
    let initialState = items.length > 0 ? Object.assign(...items) : {};
    if (formsData) {
        initialState = {
            ...initialState,
            ...formsData
        }
    }
    return initialState
}

export const prepareFormData = (record, fieldList, idName) => {
    const formData = {[idName]: record[idName]}
    fieldList.forEach((key) => {
        if(record.hasOwnProperty(key)) {
            formData[key] = record[key]
        }
    })
    return formData;
}

const isEqual = (a, b) => {
    if (!a && !b && !(a === false || b === false) ) { // equal if both empty
        return true;
    }
    return isEq(a, b);
}

export const prepareSubmitData = (originalData, newData, fieldList, idName, altIdName) => {
    const submitData = {}
    let updated = false;
    if (originalData[idName] !== newData[idName]) {
        return submitData;
    }
    fieldList.forEach((key) => {
        if(newData.hasOwnProperty(key) && !isEqual(newData[key], originalData[key])) {
            console.log(`[${key}] updated [${JSON.stringify(newData[key])}] != [${JSON.stringify(originalData[key])}].`)
            updated = true;
            submitData[key] = newData[key];
        }
    })
    if (updated) {
        submitData[idName] = newData[idName];
    }
    if (updated && altIdName && !submitData[altIdName]) {
        submitData[altIdName] = originalData[altIdName];
    }
    return submitData;
}

export const randomAmount = (min, max) => {
    let amount = Math.random() * (max - min) + min
    return amount.toFixed(0)
}

export const randomDate = (start, end) => {
    return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()))
}

export const randomArrayItem = (array) => {
    return array[Math.floor(Math.random() * array.length)]
}

export const autoFill = (name, val, formFields) => {
    if ((val === "~" || val === '*') && formFields[name].autoFill) {
        if (typeof formFields[name].autoFill === "function") {
            return formFields[name].autoFill(val);
        }
        return formFields[name].autoFill;
    }
    return val;
};

export const getFieldList = (fields) => {
    const keys = Object.keys(fields).filter(key => !!fields[key].order);
    return keys.sort((a, b) => fields[a].order - fields[b].order);
}

export const difference = (object, base) => {
    if (!object || !base) {
        return null;
    }
    function changes(object, base) {
        return transform(object, function(result, value, key) {
            if (!isEq(value, base[key])) {
                result[key] = (isObject(value) && isObject(base[key])) ? changes(value, base[key]) : value;
            }
        });
    }
    return changes(object, base);
}

export const dropEmpty = (object) => {
    return transform(object, function(result, value, key) {
        if ((isArray(value) && isEmpty(value)) || isNull(value) || value === '') {
            result[key] = undefined
        } else {
            result[key] = value
        }
    })
}

export const sortObjectKeys = (data) => {
    return Object.keys(data)
        .sort()
        .reduce((acc, key) => ({
            ...acc, [key]: data[key]
        }), {})
}

export const formatMobileNumberForApi = (mobileNumber) => {
    if (!mobileNumber) {
        return mobileNumber
    }
    mobileNumber = mobileNumber.replace(/\D/g, "");
    if (mobileNumber.length === 10) {
        mobileNumber = "+1" + mobileNumber;
    } else if (mobileNumber.length === 11) {
        mobileNumber = "+" + mobileNumber;
    }
    return mobileNumber
}

const zeroPad = (num, places) => String(num).padStart(places, '0');
const random = (min, max) => Math.floor(Math.random() * (max - min)) + min;

export const randomPhoneNumber = () => {
    return '+1-555-555-' + zeroPad(random(0, 9999), 4)
}
