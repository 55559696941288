import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import {cloneDeep} from "lodash-es";
import {useFormik} from "formik";

import DateFnsUtils from "@date-io/date-fns";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import EditIcon from "@material-ui/icons/Edit";
import {Button, DialogActions} from "@material-ui/core";

import ExPanel from "./ExPanel";
import FormInput from "./FormInput";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import Alert from "./Alert";
import {autoFill, difference, dropEmpty, fieldsInitialState, prepareFormData, prepareSubmitData} from "../utils/format";
import useApiRequest from "../hooks/useApiRequest";
import {representativeFormFields, representativeFieldList} from "../utils/constants";
import {getValidationSchema} from "../utils/validators";

const fieldList = representativeFieldList;
const updateRequestType = 'update-merchant';
const NO_UPDATES = 'Nothing to update.'

export default function EditRepresentativeForm({data, submitRequest, onUpdate, onDelete, executive, merchantId}) {
    const updateMerchantRequestId = useSelector(state => state.console.updateMerchantRequestId);

    const formFields = useMemo(() => {
        const ff = cloneDeep(representativeFormFields);
        if (executive) {
            ff.executive.disabled = true;
        }
        return ff;
    }, [executive]);

    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();
    const [warning, setWarning] = React.useState(null);

    const merchantUpdated = !!updateMerchantRequestId && !!requestId && updateMerchantRequestId === requestId;

    const [updatedFields, setUpdatedFields] = React.useState({});
    const updated = Object.keys(updatedFields).length > 0

    const handleOnDelete = () => {
        if (updated) {
            formik.setValues(fieldsInitialState(formFields, prepareFormData(data, fieldList, 'id')))
            setUpdatedFields({});
        }
        if (onDelete) {
            onDelete(data)
        }
    }

    const handleOnSubmit = (values) => {
        setWarning('');
        const submitData = prepareSubmitData(data, values, fieldList, 'id');
        if (Object.keys(submitData).length === 0) {
            setWarning(NO_UPDATES);
            return;
        }
        const merchantInfo = {id: merchantId}
        if (executive) {
            merchantInfo.executive = submitData
        } else {
            merchantInfo.owners = [submitData]
        }
        // alert(JSON.stringify(merchantInfo, null, 2));
        submitRequest(updateRequestType, merchantInfo, newRequest());
    }

    const formik = useFormik({
        initialValues: fieldsInitialState(formFields, prepareFormData(data, fieldList, 'id')),
        validationSchema: getValidationSchema(formFields),
        onSubmit: handleOnSubmit
    });

    React.useEffect(() => { // reload form fields on merchant reloaded
        formik.setValues(fieldsInitialState(formFields, prepareFormData(data, fieldList, 'id')))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    React.useEffect(() => {
        if (formik.values && data) {
            let dif = difference(dropEmpty(formik.values), {...dropEmpty(data), executive: !!data.executive});
            if(dif && warning === NO_UPDATES) {
                setWarning('')
            }
            setUpdatedFields(cloneDeep(dif))
            dif.id = data.id
            if(data.newRecord) {
                dif.newRecord = data.newRecord;
            }
            onUpdate(dif)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values, data]);

    React.useEffect(() => { // warning on fields errors
        if (Object.keys(formik.errors).length > 0 && Object.keys(formik.touched).length > 0) {
            const warning = (<>
                <div><b>Problems found</b></div>
                {Object.keys(formik.errors).map(key => {
                    return <div key={key}>{formFields[key].label}: {formik.errors[key]}</div>
                })}
            </>)
            setWarning(warning)
        } else {
            setWarning('')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.errors, formik.touched]);

    const handleOnChange = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        e.target.value = autoFill(name, val, formFields);
        formik.handleChange(e)
    }

    if (!data) {
        return null;
    }

    const Title = () => {
        const avatar = !data.executive ? "O" : executive ? "E+" : "E"
        let color = 'default'
        if (formik.values.toRemove) {
            color = 'secondary'
        } else if (updated) {
            color = 'primary'
        }
        return <><Chip
            color={color}
            label={data.id}
            onDelete={(updated || data.newRecord) ? handleOnDelete : null}
            deleteIcon={updated ? <EditIcon/> : null}
            size="small"
            avatar={<Avatar>{avatar}</Avatar>}
        />  {data.firstName} {data.lastName}</>
    }

    const Warning = () => {
        if (warning) {
            return <Alert severity="warning">{warning}</Alert>
        }
        return null;
    }

    const MerchantUpdated = () => {
        if (merchantUpdated) {
            return <Alert severity="success">Merchant representative updated</Alert>
        }
        return null;
    }

    return (
        <ExPanel title={Title()}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid item xs={12}>
                    {fieldList.map((name) => (
                        <FormInput
                            error={formik.touched[name] && formik.errors[name]}
                            key={name}
                            name={name}
                            onChange={handleOnChange}
                            onBlur={formik.handleBlur}
                            getEditValue={name => formik.values[name]}
                            formFields={formFields}
                            updated={updatedFields[name] !== undefined}
                        />
                    ))}
                    <div style={{paddingTop: "10px", paddingLeft: "10px", paddingRight: "10px", paddingBottom: "5px"}}>
                        <MerchantUpdated/>
                        <ErrorMessage errorMessage={requestError}/>
                        <Progress show={requestInProgress} requestId={requestId}/>
                        <Warning/>
                    </div>
                    <DialogActions>
                        <Button
                            disabled={requestInProgress}
                            variant="contained"
                            color={formik.values.toRemove ? "secondary" : "primary"}
                            onClick={formik.handleSubmit}
                        >
                            Save
                        </Button>
                    </DialogActions>
                </Grid>
            </MuiPickersUtilsProvider>
        </ExPanel>
    )
}