import React from 'react';

import Grid from "@material-ui/core/Grid";

import ExPanel from "./ExPanel";
import LoanInfoComponent from "./LoanInfoComponent";

export default function LoanInfoPanel({submitRequest, disableDataTypes, openPaymentLink}) {
    return (
        <ExPanel title="Loan Application Info">
            <Grid item xs={12}>
                <LoanInfoComponent
                    disableDataTypes={disableDataTypes}
                    submitRequest={submitRequest}
                    openPaymentLink={openPaymentLink}
                />
            </Grid>
        </ExPanel>
    )
}