import React from "react";
import {autoFill, fieldsInitialState} from "../utils/format";

export default function useFields(formFields, formData, fieldList) {
    const initState = fieldsInitialState(formFields, formData);
    const [fields, setFields] = React.useState(initState);
    function handleOnChange(e) {
        if(!e) {
            setFields(initState);
            return;
        }
        let val = e.target.value;
        let name = e.target.name;
        val = autoFill(name, val, formFields);
        setFields(fields => ({...fields, [name]: val}));
    }
    function handleOnClean() {
        setFields(fieldsInitialState(formFields));
    }
    const handleOnAutoFill = () => {
        if (fieldList) {
            fieldList.forEach((name) => {
                const val = autoFill(name, '~', formFields);
                if (val !== '~') {
                    setFields(fields => ({ ...fields, [name]: val }));
                }
            })
        }
    }
    return [fields, handleOnChange, handleOnClean, handleOnAutoFill]
}