import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Title from './Title';

export default function Panel({title, children, height=240}) {

    const useStyles = makeStyles((theme) => ({
        paper: {
            padding: theme.spacing(2),
            paddingBottom: theme.spacing(4),
            display: 'flex',
            overflow: 'auto',
            flexDirection: 'column',
            maxHeight: height
        },
    }));
    
    const classes = useStyles();

    return (
        <Paper className={classes.paper}>
            <Title>{title}</Title>
            {children}
        </Paper>
    )
}