import React from "react";
import {useSelector} from "react-redux";

import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Chip from "@material-ui/core/Chip";

import Panel from "./Panel";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import ReloadButton from "./ReloadButton";
import SignupDialog from "./SignupDialog";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import useApiRequest from "../hooks/useApiRequest";

export default function RecentMerchantsPanel({submitRequest, submitSignupInfoRequest, openSignup, createSignup}) {
    const merchants = useSelector(state => state.console.recentMerchants);
    const [selectedRow, setSelectedRow] = React.useState('');
    const [openSignupInfo, setOpenSignupInfo] = React.useState(false);
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();

    const handleReload = () => {
        submitRequest(null, newRequest());
    }

    const handleSignupOpen = (row) => {
        setSelectedRow(row);
        setOpenSignupInfo(true);
    }

    const handleSignupClose = () => {
        setOpenSignupInfo(false);
    }

    const renderIDColumn = (row) => {
        if (!row || !row.id) {
            return null;
        }
        const isSelected = !!selectedRow && selectedRow.id === row.id
        const deleteIcon = isSelected ? <CheckCircleIcon/> : null;
        const onDelete = isSelected ? () => setSelectedRow(null) : null;
        return <CopyToClipboard text={row.id}>
            <Chip
                label={row.id}
                size="small"
                onClick={() => handleSignupOpen(row)}
                deleteIcon={deleteIcon}
                onDelete={onDelete}
            />
        </CopyToClipboard>
    }

    const MerchantsTable = () => {
        if (!merchants || requestInProgress) {
            return null;
        }
        return (
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Merchant Name</TableCell>
                        <TableCell>Partner Name</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {merchants.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell>
                                {renderIDColumn(row)}
                            </TableCell>
                            <TableCell>{row.createdAt}</TableCell>
                            <TableCell>{row.businessLegalName}</TableCell>
                            <TableCell>{row.partnerName}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        )
    }

    return (
        <Panel title="Recent Merchants (one per partner)" height={600}>
            <ReloadButton hide={requestInProgress} onClick={handleReload}/>
            <MerchantsTable/>
            <ErrorMessage errorMessage={requestError}/>
            <Progress show={requestInProgress} requestId={requestId}/>
            {openSignupInfo && <SignupDialog
                data={selectedRow}
                openSignup={openSignup}
                onClose={handleSignupClose}
                submitRequest={submitSignupInfoRequest}
                createLegacySignup={createSignup}
                parentRequestId={requestId}
            />}
        </Panel>
    )
}
