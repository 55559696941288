import React from 'react';
import { connect } from "react-redux";

import AppLayout from "../components/AppLayout";
import { submitIpcRequest } from "../store/actions/consoleActions";
import CloudWatchLogsPanel from "../components/CloudWatchLogsPanel";
import AuditLogsPanel from "../components/AuditLogsPanel";

function CloudWatchPage(props) {
    return (
        <AppLayout title="Logs">
            <CloudWatchLogsPanel
                submitRequest={props.submitIpcRequest}
            />
            <AuditLogsPanel
                submitRequest={props.submitIpcRequest}
            />
        </AppLayout>
    )
}

export default connect(null, {submitIpcRequest})(
    CloudWatchPage
);
