import React from "react";

import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";

import DraggableDialog from "./DraggableDialog";
import JsonView from "./JsonView";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import useApiRequest from "../hooks/useApiRequest";
import {useSelector} from "react-redux";
import {formatAuditTrail} from "../utils/format";

export default function AuditTrailDialog({data, onClose, submitRequest, parentRequestId}) {
    const auditTrailRequestId = useSelector(state => state.console.auditTrailRequestId);
    const auditTrail = useSelector(state => state.console.auditTrail);
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest(parentRequestId);

    const decryptedData = !!auditTrailRequestId && auditTrailRequestId === requestId && !!auditTrail.trailList &&
        auditTrail.trailList.length === 1 && formatAuditTrail(auditTrail.trailList[0])

    React.useEffect(() => { // reload if data encrypted to decrypt data
        if (data && data.data && data.data.dataEncrypted) {
            submitRequest('audit-trail', {
                entityType: data.entityType,
                entityId: data.entityId,
                eventDate: data.eventDate
            }, newRequest())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <Dialog
            PaperComponent={DraggableDialog}
            aria-labelledby="draggable-dialog-title"
            open={true}
            maxWidth="md"
            fullWidth={true}
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                Audit Trail Data
            </DialogTitle>
            <DialogContent dividers={true}>
                <JsonView data={decryptedData || data} shouldExpandNode={(keyPath, data, level) => level < 3}/>
            </DialogContent>
            <div style={{paddingTop: "10px", paddingLeft: "10px", paddingRight: "10px", paddingBottom: "5px"}}>
                <ErrorMessage errorMessage={requestError}/>
                <Progress show={requestInProgress} requestId={requestId}/>
            </div>
            <DialogActions>
                <Button variant="contained" onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}
