import React from 'react';

import {CopyToClipboard} from 'react-copy-to-clipboard';
import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from "@material-ui/core";

import FormInput from "./FormInput";
import DraggableDialog from "./DraggableDialog";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import Alert from "./Alert";
import {partnerFormFieldList, partnerFormFields} from "../utils/constants";
import useFields from "../hooks/useFields";
import useApiRequest from "../hooks/useApiRequest";
import useApiRequestExecuted from "../hooks/useApiRequestExecuted";
import useSettings from "../hooks/useSettings";
import ProductPlanList from "./ProductPlanList";
import useProductPlans from "../hooks/useProductPlans";

const formFields = partnerFormFields;
const fieldList = partnerFormFieldList;
const updateRequestType = 'update-partner';
const deleteRequestType = 'delete-partner';

export default function EditPartnerDialog({data, onClose, submitRequest}) {
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();
    const partnerDeleted = useApiRequestExecuted('deletePartnerRequestId', requestId);
    const partnerUpdated = useApiRequestExecuted('updatePartnerRequestId', requestId);

    const {supportedPlans, handlePlanAdd, handlePlanUpdate, handlePlanRemove, handlePlanUndo} = useProductPlans(data.supportedPlans)

    useSettings(formFields)
    const [fields, handleOnChange] = useFields(formFields, data);

    const handleOnSave = () => {
        submitRequest(updateRequestType, {...fields, supportedPlans}, newRequest());
    }

    const handleOnDelete = () => {
        submitRequest(deleteRequestType, fields, newRequest());
    }

    const PartnerUpdated = () => {
        if (partnerUpdated) {
            return <Alert severity="success">Partner updated</Alert>
        }
        return null;
    }

    const PartnerDeleted = () => {
        if (partnerDeleted) {
            return <Alert severity="success">Partner deleted</Alert>
        }
        return null;
    }

    return (
        <Dialog
            PaperComponent={DraggableDialog}
            aria-labelledby="draggable-dialog-title"
            open={true}
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                Edit Partner <CopyToClipboard text={data.id}>
                    <Chip label={data.id} size="small" color="primary"/>
                </CopyToClipboard>
            </DialogTitle>
            <DialogContent dividers={true}>
                {fieldList.map((name) => (
                    <FormInput
                        disabled={partnerDeleted}
                        key={name}
                        name={name}
                        onChange={handleOnChange}
                        getEditValue={name => fields[name]}
                        formFields={formFields}
                    />
                ))}
                <ProductPlanList
                    supportedPlans={supportedPlans}
                    onRemove={handlePlanRemove}
                    onAdd={handlePlanAdd}
                    onUpdate={handlePlanUpdate}
                    onUndo={handlePlanUndo}
                />
            </DialogContent>
            <div style={{paddingTop: "10px", paddingLeft: "10px", paddingRight: "10px", paddingBottom: "5px"}}>
                <ErrorMessage errorMessage={requestError}/>
                <Progress show={requestInProgress}/>
                <PartnerUpdated/>
                <PartnerDeleted/>
            </div>
            <DialogActions>
                <Button disabled={requestInProgress || partnerDeleted} variant="contained" color="secondary" onClick={handleOnDelete}>Delete</Button>
                <Button disabled={requestInProgress || partnerDeleted} variant="contained" color="primary" onClick={handleOnSave}>Save</Button>
                <Button disabled={requestInProgress} variant="contained" onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}