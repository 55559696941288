import React from 'react';
import {useSelector} from "react-redux";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

import ExPanel from "./ExPanel";
import SubmitButton from "./SubmitButton";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import useApiRequest from "../hooks/useApiRequest";

export default function DecryptPanel({decryptWithVault}) {
    const decryptedValue = useSelector(state => state.console.decryptedValue);
    const decryptedToken = useSelector(state => state.console.tokenToDecrypt);
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();
    const [tokenToDecrypt, setTokenToDecrypt] = React.useState('');

    const handleDecrypt = () => {
        if (tokenToDecrypt) {
            decryptWithVault(tokenToDecrypt, newRequest());
        }
    }

    const DecryptedValue = () => {
        if (decryptedValue && !requestInProgress && decryptedToken === tokenToDecrypt) {
            return (
                <TextField
                    label="Decrypted value"
                    variant="outlined"
                    margin="normal"
                    size="small"
                    fullWidth
                    value={decryptedValue}
                />
            )
        }
        return null;
    }

    return (
        <ExPanel title="Decrypt">
            <Grid item xs={12}>
                <form>
                    <TextField
                        label="Token to decrypt"
                        variant="outlined"
                        margin="normal"
                        size="small"
                        required
                        fullWidth
                        value={tokenToDecrypt}
                        onChange={e => {
                            setTokenToDecrypt(e.target.value);
                        }}
                    />
                    <SubmitButton onClick={handleDecrypt} />
                </form>
                <ErrorMessage errorMessage={requestError}/>
                <Progress show={requestInProgress} requestId={requestId}/>
                <DecryptedValue />
            </Grid>
        </ExPanel>
    )
}
