import React from "react";

import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";

import DraggableDialog from "./DraggableDialog";
import LoanInfoComponent from "./LoanInfoComponent";

export default function LoanInfoDialog({onClose, submitRequest, disableDataTypes, loanId, openPaymentLink}) {
    return (
        <Dialog
            PaperComponent={DraggableDialog}
            aria-labelledby="draggable-dialog-title"
            open={true}
            maxWidth="md"
            fullWidth={true}
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                Loan Application Info
            </DialogTitle>
            <DialogContent dividers={true}>
                <LoanInfoComponent
                    submitRequest={submitRequest}
                    openPaymentLink={openPaymentLink}
                    loanId={loanId}
                    disableDataTypes={disableDataTypes}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}