import React from 'react';
import {useSelector} from "react-redux";
import DateFnsUtils from "@date-io/date-fns";
import {useFormik} from 'formik';
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";

import {Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import Avatar from "@material-ui/core/Avatar";
import EditIcon from "@material-ui/icons/Edit";

import FormInput from "./FormInput";
import DraggableDialog from "./DraggableDialog";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import Alert from "./Alert";
import useApiRequest from "../hooks/useApiRequest";
import {getValidationSchema} from "../utils/validators";
import {difference, dropEmpty, fieldsInitialState, prepareFormData, prepareSubmitData} from "../utils/format";
import {merchantFormFieldList, merchantFormFields} from "../utils/constants";
import useSettings from "../hooks/useSettings";
import {get} from "lodash-es";

const formFields = merchantFormFields;
const fieldList = merchantFormFieldList;
const reloadRequestType = 'reload-merchant';
const updateRequestType = 'update-merchant';
const NO_UPDATES = 'Nothing to update.'

const transformFromApiData = (data) => {
    const newData = {
        ...data,
        defaultPricingGrid: get(data, ['productPlan', 'pricingGrid'], ''),
        defaultMdrGrid: get(data, ['productPlan', 'mdrGrid'], '')
    }
    delete newData['productPlan']

    if (newData.merchantPortalTransactionEnabled === false) newData.merchantPortalTransactionEnabled = 'FALSE'
    else if(newData.merchantPortalTransactionEnabled === true) newData.merchantPortalTransactionEnabled = 'TRUE'
    else  newData.merchantPortalTransactionEnabled = 'FROM ACCOUNT'

    return newData
}

const transformToApiData = (data) => {
    const newData = {
        ...data
    }
    if (data.defaultPricingGrid && data.defaultMdrGrid) {
        newData.productPlan = {
            pricingGrid: data.defaultPricingGrid,
            mdrGrid: data.defaultMdrGrid
        }
    }
    delete newData['defaultPricingGrid']
    delete newData['defaultMdrGrid']
    if(newData.merchantPortalTransactionEnabled==='TRUE') newData.merchantPortalTransactionEnabled = true
         else if(newData.merchantPortalTransactionEnabled==='FALSE') newData.merchantPortalTransactionEnabled = false
            else newData.merchantPortalTransactionEnabled = null
    return newData
}

const cleanForComparison = (data) => {
    const newData = {
        ...data
    }
    if (newData.productPlan) {
        delete newData.productPlan['createdAt']
        delete newData.productPlan['defaultPlan']
    }
    return newData;
}

export default function EditMerchantDialog({data, onClose, submitRequest, parentRequestId}) {
    const updateMerchantRequestId = useSelector(state => state.console.updateMerchantRequestId);
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest(parentRequestId);
    const [updatedFields, setUpdatedFields] = React.useState({});
    const formUpdated = Object.keys(updatedFields).length > 0

    const [warning, setWarning] = React.useState(null);

    const merchantUpdated = !!updateMerchantRequestId && !!requestId && updateMerchantRequestId === requestId;

    useSettings(formFields)

    const handleOnSubmit = (values) => {
        setWarning('');
        const submitData = prepareSubmitData(cleanForComparison(data), transformToApiData(values), [...fieldList, 'productPlan'], 'id', 'federalEINHash');
        if (Object.keys(submitData).length === 0) {
            setWarning(NO_UPDATES);
            return;
        }
        submitRequest(updateRequestType, submitData, newRequest());
    }

    const handleOnReload = () => {
        setWarning('');
        submitRequest(reloadRequestType, {id: data.id}, newRequest());
    }

    const MerchantUpdated = () => {
        if (merchantUpdated && Object.keys(updatedFields).length === 0) {
            return <Alert severity="success">Merchant updated</Alert>
        }
        return null;
    }

    const handleOnDelete = () => {
        formik.setValues(fieldsInitialState(formFields, prepareFormData(data, fieldList, 'id')))
    }

    const formik = useFormik({
        initialValues: fieldsInitialState(formFields, prepareFormData(transformFromApiData(data), fieldList, 'id')),
        validationSchema: getValidationSchema(formFields),
        onSubmit: handleOnSubmit
    });

    React.useEffect(() => { // find updated fields
        if (formik.values && data) {
            let dif = difference(dropEmpty(formik.values), dropEmpty(transformFromApiData(data)));
            if(dif && warning === NO_UPDATES) {
                setWarning('')
            }
            setUpdatedFields(dif)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values, data]);

    React.useEffect(() => { // reload form fields on merchant reloaded
        formik.setValues(fieldsInitialState(formFields, prepareFormData(transformFromApiData(data), fieldList, 'id')))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    React.useEffect(() => { // warning on fields errors
        if (Object.keys(formik.errors).length > 0 && Object.keys(formik.touched).length > 0) {
            const warning = (<>
                <div><b>Problems found</b></div>
                {Object.keys(formik.errors).map(key => {
                    return <div key={key}>{formFields[key].label}: {formik.errors[key]}</div>
                })}
            </>)
            setWarning(warning)
        } else {
            setWarning('')
        }
    }, [formik.errors, formik.touched]);

    const Warning = () => {
        if (warning) {
            return <Alert severity="warning">{warning}</Alert>
        }
        return null;
    }

    return (
        <Dialog
            PaperComponent={DraggableDialog}
            aria-labelledby="draggable-dialog-title"
            open={true}
            fullWidth={true}
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                Edit Merchant <CopyToClipboard text={data.id}>
                    <Chip
                        label={data.id}
                        size="small"
                        color={formUpdated ? "primary" : "default"}
                        onDelete={formUpdated ? handleOnDelete : null}
                        deleteIcon={formUpdated ? <EditIcon/> : null}
                        avatar={<Avatar>M</Avatar>}
                        onClick={handleOnReload}
                    />
                </CopyToClipboard>
            </DialogTitle>
            <DialogContent dividers={true}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    {fieldList.map((name) => (
                        <FormInput
                            error={formik.touched[name] && formik.errors[name]}
                            disabled={requestInProgress}
                            key={name}
                            name={name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            getEditValue={name => formik.values[name]}
                            formFields={formFields}
                            updated={updatedFields[name]}
                        />
                    ))}
                </MuiPickersUtilsProvider>
            </DialogContent>
            <div style={{paddingTop: "10px", paddingLeft: "10px", paddingRight: "10px", paddingBottom: "5px"}}>
                <ErrorMessage errorMessage={requestError}/>
                <Progress show={requestInProgress} requestId={requestId}/>
                <Warning/>
                <MerchantUpdated/>
            </div>
            <DialogActions>
                <Button disabled={requestInProgress} variant="contained" color="primary" onClick={formik.handleSubmit}>Save</Button>
                <Button disabled={requestInProgress} variant="contained" onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}