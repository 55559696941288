import React from 'react';
import {useSelector} from "react-redux";

import Grid from "@material-ui/core/Grid";
import { DataGrid } from '@mui/x-data-grid';
import Chip from "@material-ui/core/Chip";
import EditIcon from "@material-ui/icons/Edit";

import ExPanel from "./ExPanel";
import ErrorMessage from "./ErrorMessage";
import JsonView from "./JsonView";
import ReloadButton from "./ReloadButton";
import Progress from "./Progress";
import Link from "@material-ui/core/Link";
import EditPartnerDialog from "./EditPartnerDialog";
import JsonSwitch from "./JsonSwitch";
import useApiRequest from "../hooks/useApiRequest";

export default function PartnersInfoPanel({submitPartnersInfoRequest, openSignup, submitRequest}) {
    const partnersInfo = useSelector(state => state.console.partnersInfo);
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();
    const [selectedRow, setSelectedRow] = React.useState('');
    const [jsonView, setJsonView] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const dataPresent = !!partnersInfo && !!partnersInfo.accounts;

    const idCellRenderer = ({row, value}) => {
        const editedRow = !!selectedRow && selectedRow.id === row.id
        const deleteIcon = editedRow ? <EditIcon /> : null;
        const onDelete = editedRow ? () => setSelectedRow(null) : null;
        return <Chip
            label={value}
            size="small"
            color={row.blocked ? "secondary" : "default"}
            onClick={() => {handleEdit(row)}}
            deleteIcon={deleteIcon}
            onDelete={onDelete}
        />
    }
    const aliasCellRenderer = ({value}) => {
        return <Link href="#" color="primary" onClick={(e) => {e.preventDefault(); openSignup(value, true)}}>
            {value}
        </Link>
    }

    const columns = [
        {field: 'id', headerName: 'ID', width: 400, renderCell: idCellRenderer},
        {field: 'name', headerName: 'Name', width: 250},
        {field: 'alias', headerName: 'Alias', width: 130, renderCell: aliasCellRenderer},
        {field: 'defaultVertical', headerName: 'Default Vertical', width: 130},
    ]

    const handleInfoRequest = () => {
        submitPartnersInfoRequest({}, newRequest());
    }

    const handleEdit = (row) => {
        setSelectedRow(row);
        setOpenDialog(true);
    }

    const handleEditClose = () => {
        setOpenDialog(false);
    }

    const PartnersGrid = () => {
        return <div style={{ height: 450, width: '100%' }}>
            <DataGrid
                rowHeight={46}
                rowsPerPageOptions={[10]}
                pageSize={10}
                rows={partnersInfo.accounts}
                hideFooterSelectedRowCount={true}
                columns={columns}
                loading={requestInProgress}
                density='compact'
            />
        </div>
    }

    const PartnersInfo = () => {
        if (!dataPresent) {
            return null;
        }
        if (jsonView) {
            return <JsonView data={partnersInfo} shouldExpandNode={(keyPath, data, level) => level < 3}/>
        }
        return <PartnersGrid/>
    }

    return (
        <ExPanel title="Partners Info">
            <Grid item xs={12}>
                <ReloadButton onClick={handleInfoRequest} disabled={requestInProgress}/>
                <JsonSwitch hide={!dataPresent} jsonView={jsonView} setJsonView={setJsonView}/>
                <Progress show={requestInProgress} requestId={requestId}/>
                <ErrorMessage errorMessage={requestError}/>
                <PartnersInfo/>
                {openDialog && <EditPartnerDialog
                    data={selectedRow}
                    onClose={handleEditClose}
                    submitRequest={submitRequest}
                />}
            </Grid>
        </ExPanel>
    )
}
