import React from "react";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";

import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Tooltip, Zoom
} from "@material-ui/core";

import DraggableDialog from "./DraggableDialog";
import FormInput from "./FormInput";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import {fieldsInitialState, prepareFormData} from "../utils/format";
import Alert from "./Alert";
import useApiRequest from "../hooks/useApiRequest";
import useApiRequestExecuted from "../hooks/useApiRequestExecuted";
import Avatar from "@material-ui/core/Avatar";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

const formFields = {
    "alloyEntityToken": {
        label: 'Alloy Entity Token',
        initValue: ''
    },
    "outcome": {
        label: 'Outcome',
        type: 'select',
        data: [
            {
                id: 'Approved'
            },
            {
                id: 'Denied'
            },
        ],
        initValue: 'Approved'
    },
    "reasons": {
        label: 'Reasons',
        initValue: "TEST"
    },
    "reviewer": {
        label: 'Reviewer',
        initValue: "test@wisetack.com"
    },
}

const fieldList = [
    'alloyEntityToken',
    'outcome',
    'reasons',
    'reviewer'
]

const reviewRequestType = 'review-merchant';

export default function MerchantReviewDialog({data, onClose, submitRequest, parentRequestId}) {
    const [fields, setFields] = React.useState(fieldsInitialState(formFields, prepareFormData(data, fieldList, 'id')));
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest(parentRequestId);
    const merchantReviewed = useApiRequestExecuted('reviewMerchantRequestId', requestId);
    const [dataRequired, setDataRequired] = React.useState(true);

    React.useEffect(() => {
        const formData = prepareFormData(data, fieldList, 'id');
        formData.outcome = data.transactionsEnabled ? 'Approved' : 'Denied';
        setFields(fieldsInitialState(formFields, formData));
    }, [data]);

    React.useEffect(() => {
        if (fields) {
            for (const key of fieldList) {
                if (!fields[key]) {
                    setDataRequired(true);
                    return;
                }
            }
            setDataRequired(false);
        }
    }, [fields]);

    const handleOnChange = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        setFields(fields => ({ ...fields, [name]: val }));
    }

    const handleOnSubmit = () => {
        submitRequest(reviewRequestType, fields, newRequest());
    }

    const MerchantReviewed = () => {
        if (merchantReviewed) {
            return <Alert severity="success">Merchant reviewed</Alert>
        }
        return null;
    }

    return (
        <Dialog
            PaperComponent={DraggableDialog}
            aria-labelledby="draggable-dialog-title"
            open={true}
            fullWidth={true}
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                <Tooltip TransitionComponent={Zoom} title="Uses same endpoint as manual review from Alloy">
                    <InfoOutlinedIcon color="action" style={{marginRight: "5px"}}/>
                </Tooltip>
                Review Merchant <CopyToClipboard text={data.id}>
                    <Chip label={data.id} size="small" color="primary" avatar={<Avatar>M</Avatar>}/>
                </CopyToClipboard>
            </DialogTitle>
            <DialogContent dividers={true}>
                {fieldList.map((name) => (
                    <FormInput
                        disabled={requestInProgress}
                        key={name}
                        name={name}
                        onChange={handleOnChange}
                        getEditValue={name => fields[name]}
                        formFields={formFields}
                    />
                ))}
            </DialogContent>
            <div style={{paddingTop: "10px", paddingLeft: "10px", paddingRight: "10px", paddingBottom: "5px"}}>
                <ErrorMessage errorMessage={requestError}/>
                <Progress show={requestInProgress} requestId={requestId}/>
                <MerchantReviewed/>
            </div>
            <DialogActions>
                <Button disabled={requestInProgress || dataRequired} variant="contained" color="primary" onClick={handleOnSubmit}>Submit</Button>
                <Button disabled={requestInProgress} variant="contained" onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}
