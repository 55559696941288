import React from "react";
import {useFormik} from "formik";
import * as Yup from "yup";

import Grid from "@material-ui/core/Grid";
import {Button, DialogActions} from "@material-ui/core";

import ExPanel from "./ExPanel";
import FormInput from "./FormInput";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import useApiRequest from "../hooks/useApiRequest";
import {fieldsInitialState, getFieldList} from "../utils/format";
import {getValidationSchema} from "../utils/validators";
import {useSelector} from "react-redux";
import JsonView from "./JsonView";

const formFields = {
    "alloyEntityToken": {
        label: 'Alloy Entity Token',
        initValue: '',
        order: 1
    },
    "entityId": {
        label: 'Entity ID',
        initValue: '',
        order: 2
    }
}
const fieldList = getFieldList(formFields)

const requestType = "get-alloy-data"

export default function AlloyDataPanel({submitRequest}) {
    const alloyRequestId = useSelector(state => state.console.alloyRequestId);
    const alloyData = useSelector(state => state.console.alloyData);

    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();
    const requestExecuted = !!alloyRequestId && !!requestId && alloyRequestId === requestId;

    const handleOnSubmit = (values) => {
        submitRequest(requestType, values, newRequest());
    }

    const formik = useFormik({
        initialValues: fieldsInitialState(formFields),
        validationSchema: getValidationSchema(formFields).shape(
            {
                "alloyEntityToken": Yup.string().when('entityId', {
                    is: (entityId) => !entityId ||  entityId.length === 0,
                    then: Yup.string().required('One of fields is required'),
                    otherwise: Yup.string()
                }),
                "entityId": Yup.string().when('alloyEntityToken', {
                    is: (alloyEntityToken) => !alloyEntityToken ||  alloyEntityToken.length === 0,
                    then: Yup.string().required('One of fields is required'),
                    otherwise: Yup.string()
                }),
            },
            [["alloyEntityToken", "entityId"]]
        ),
        onSubmit: handleOnSubmit
    });

    const AlloyData = () => {
        if(requestExecuted && alloyData) {
            return <JsonView data={alloyData} shouldExpandNode={(keyPath, data, level) => level < 3}/>
        }
        return null;
    }

    return (
        <ExPanel title="Alloy Data" tooltip="Used to import data from Alloy if required.">
            <Grid item xs={12}>
                <form>
                    {fieldList.map((name) => (
                        <FormInput
                            error={formik.touched[name] && formik.errors[name]}
                            key={name}
                            name={name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            getEditValue={name => formik.values[name]}
                            formFields={formFields}
                        />
                    ))}
                </form>
                <ErrorMessage errorMessage={requestError}/>
                <Progress show={requestInProgress}/>
                <DialogActions>
                    <Button disabled={requestInProgress} variant="contained" color="primary" onClick={formik.handleSubmit}>Submit</Button>
                </DialogActions>
                <AlloyData/>
            </Grid>
        </ExPanel>
    )
}