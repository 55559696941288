import React from 'react';
import {connect} from "react-redux";

import Grid from '@material-ui/core/Grid';

import AppLayout from '../components/AppLayout';
import RecentMerchantsPanel from "../components/RecentMerchantsPanel";
import RecentLoansPanel from "../components/RecentLoansPanel";
import { submitIpcRequest, openPaymentLink, openSignup } from "../store/actions/consoleActions";
import useCredentials from "../hooks/useCredentials";
import {VERSION} from "../utils/constants";

function DashboardPage({submitIpcRequest, openPaymentLink, openSignup}) {

    const handleSignupInfoRequest = React.useCallback((data, requestId) => {
        submitIpcRequest('signup-info', data, requestId)
    }, [submitIpcRequest]);

    const {loginRequired} = useCredentials();

    return (
        <AppLayout title={loginRequired ? "Wisetack Console " + VERSION : "Dashboard"}>
            <Grid container spacing={3}>
                { !loginRequired &&
                    <>
                        <Grid item xs={12}>
                            <RecentMerchantsPanel
                                openSignup={openSignup}
                                submitSignupInfoRequest={handleSignupInfoRequest}
                                createSignup={(data, requestId) => submitIpcRequest('create-legacy-signup', data, requestId)}
                                submitRequest={(data, requestId) => submitIpcRequest('recent-partner-merchants', data, requestId)}
                            />
                        </Grid>
                        <Grid item xs={12} >
                            <RecentLoansPanel
                                submitRequest={submitIpcRequest}
                                openPaymentLink={openPaymentLink}
                            />
                        </Grid>
                    </>
                }
            </Grid>
        </AppLayout>
    )
}

export default connect(null, {
    submitIpcRequest,
    openPaymentLink,
    openSignup
})(
    DashboardPage
);

