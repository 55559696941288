import React from "react";
import { v4 as uuid } from 'uuid';
import {useSelector} from "react-redux";
import {formatErrorMessage} from "../utils/format";

export default function useApiRequest(parentRequestId) {
    const apiRequests = useSelector(state => state.console.apiRequests);
    const apiError = useSelector(state => state.console.apiError);
    const [requestId, setRequestId] = React.useState(parentRequestId || '');
    const requestInProgress = !!requestId && !!apiRequests[requestId];
    function newRequest() {
        const requestId = uuid();
        setRequestId(requestId);
        return requestId;
    }
    const requestError = formatErrorMessage(apiError, requestId);
    return [requestId, requestInProgress, requestError, newRequest]
}