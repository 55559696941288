import React, {useMemo} from "react";
import { v4 as uuid } from 'uuid';
import {omit} from "lodash-es";

import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Avatar,
    Tooltip,
    Zoom
} from "@material-ui/core";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import DraggableDialog from "./DraggableDialog";
import EditRepresentativeForm from "./EditRepresentativeForm";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import useApiRequest from "../hooks/useApiRequest";

const reloadRequestType = 'reload-merchant';

export default function EditMerchantRepresentativesDialog({data, onClose, submitRequest, parentRequestId}) {
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest(parentRequestId);

    const executive = useMemo(() => data.executive ? {...data.executive, toRemove: false} : null, [data]);
    const owners = useMemo(() => data.owners ? data.owners.map(item => {return {...item, toRemove: false}}) : [], [data]);

    const [newItems, setNewItems] = React.useState([]);
    const [updatedItems, setUpdatedItems] = React.useState({});
    const formUpdated = Object.keys(updatedItems).length > 0

    React.useEffect(() => {
        if (data) {
            console.log(`merchant [${data.id}] data updated`)
            setUpdatedItems({});
            setNewItems([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const handleOnReload = () => {
        submitRequest(reloadRequestType, {id: data.id}, newRequest());
    }

    const handleOnAdd = () => {
        setNewItems([
            ...newItems,
            {
                id: uuid(),
                newRecord: true,
                alloyEntityToken: "",
                city: "",
                dob: null,
                email: "",
                firstName: "",
                homeAddress: "",
                lastName: "",
                mobileNumber: "",
                ownership: "",
                ssn: "",
                ssn4: "",
                state: "",
                toRemove: false,
                zip: ""
            }
        ])
    }

    const handleOnDelete = (item) => {
        if (item.newRecord) {
            setNewItems(newItems.filter(i => i.id !== item.id));
        }
        setUpdatedItems(omit(updatedItems, item.id));
    }

    const handleRepresentativeUpdate = (item) => {
        let items = {
            ...updatedItems,
            [item.id]: item
        }
        if (Object.keys(item).length === 1) {
            delete items[item.id]
        }
        setUpdatedItems(items);
        console.log(items)
    }

    return (
        <Dialog
            PaperComponent={DraggableDialog}
            aria-labelledby="draggable-dialog-title"
            open={true}
            fullWidth={true}
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                <Tooltip TransitionComponent={Zoom} title="Using this form you can edit only merchant representatives data, to edit merchant portal users data please use 'Users' menu.">
                    <InfoOutlinedIcon color="action" style={{marginRight: "5px"}}/>
                </Tooltip>
                    Edit Representatives <Chip
                    label={data.id}
                    size="small"
                    color={formUpdated ? "primary" : "default"}
                    avatar={<Avatar>M</Avatar>}
                    onClick={handleOnReload}
                />
            </DialogTitle>
            <DialogContent dividers={true}>
                {executive && <EditRepresentativeForm
                    onUpdate={handleRepresentativeUpdate}
                    data={executive}
                    onDelete={handleOnDelete}
                    executive={true}
                    submitRequest={submitRequest}
                    merchantId={data.id}
                />}
                {owners.map((item) => (<EditRepresentativeForm
                    key={item.id}
                    onUpdate={handleRepresentativeUpdate}
                    data={item}
                    onDelete={handleOnDelete}
                    executive={false}
                    submitRequest={submitRequest}
                    merchantId={data.id}
                />))}
                {newItems.map((item) => (<EditRepresentativeForm
                    key={item.id}
                    onUpdate={handleRepresentativeUpdate}
                    data={item}
                    onDelete={handleOnDelete}
                    executive={false}
                    submitRequest={submitRequest}
                    merchantId={data.id}
                />))}
            </DialogContent>
            <div style={{paddingTop: "10px", paddingLeft: "10px", paddingRight: "10px", paddingBottom: "5px"}}>
                <ErrorMessage errorMessage={requestError}/>
                <Progress show={requestInProgress} requestId={requestId}/>
            </div>
            <DialogActions>
                <Button disabled={requestInProgress} variant="contained" color="primary" onClick={handleOnAdd}>Add</Button>
                <Button disabled={requestInProgress} variant="contained" onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}
