import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import { useForm } from 'react-hook-form';

import {
    Button,
    Dialog,
    DialogActions, DialogContent,
    DialogTitle
} from "@material-ui/core";

import DraggableDialog from "./DraggableDialog";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import useApiRequest from "../hooks/useApiRequest";
import Alert from "./Alert";
import logo from '../assets/img/icon.png';
import useCredentials from "../hooks/useCredentials";
import {FormInputCtr} from "./FormInputCtr";

const formFields = {
    "email": {
        label: 'Email',
        initValue: '',
        rules: {
            required: 'Email required',
            pattern: {
                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: 'Must use a valid email',
            },
        }
    },
    "password": {
        label: 'Password',
        type: 'password',
        initValue: '',
        rules: {
            required: 'Password required'
        }
    },
    "newPassword": {
        label: 'New password',
        type: 'password',
        initValue: '',
    },
    "verificationCode": {
        label: 'Verification code',
        initValue: '',
    },
    "rememberMe": {
        label: 'Remember me',
        type: 'switch',
        initValue: false,
    }
};

const fieldList = ["email", "password", "newPassword", "verificationCode", "rememberMe"];

const signInRequestType = 'cognito-sign-in';
const refreshRequestType = 'refresh-cognito-session';

export default function LoginDialog({submitRequest, onClose}) {
    const history = useHistory();
    const dispatch = useDispatch();

    const securityTokenExpired = useSelector(state => state.console.securityTokenExpired);
    const cognitoSignIn = useSelector(state => state.console.cognitoSignIn);
    const cognitoSignInRequestId = useSelector(state => state.console.cognitoSignInRequestId)
    const formsData = useSelector(state => state.console.formsData[signInRequestType]);
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();
    const {username, loginRequired} = useCredentials();

    const credentials = !!requestId && requestId === cognitoSignInRequestId && !!cognitoSignIn && cognitoSignIn.credentials

    const { handleSubmit, control } = useForm({
        defaultValues: {
            ...formsData,
            email: username || '',
            password: ''
        }
    });

    const passwordResetRequired = cognitoSignIn && cognitoSignIn.passwordResetRequired
    const newPasswordRequired = cognitoSignIn && cognitoSignIn.newPasswordRequired
    const logoutEnabled = !!cognitoSignIn && cognitoSignIn.credentials
    const refreshToken = !!cognitoSignIn && !!cognitoSignIn.refreshToken && cognitoSignIn.refreshToken.token
    const refreshingSession = securityTokenExpired && refreshToken && requestInProgress

    formFields.verificationCode.type = passwordResetRequired ? 'text' : 'hidden'
    formFields.newPassword.type = newPasswordRequired || passwordResetRequired ? 'password' : 'hidden'

    const handleOnSignIn = (data) => {
        submitRequest(signInRequestType, data, newRequest());
    }

    const handleOnSignOut = () => {
        dispatch({type: "sign-out"})
        if(history.location.pathname !== '/') {
            history.push("/")
        }
    }

    useEffect(() => {
        if (securityTokenExpired && refreshToken && username) {
            submitRequest(refreshRequestType, {refreshToken, email: username}, newRequest());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [securityTokenExpired, refreshToken]);

    useEffect(() => {
        if(credentials) {
            if (securityTokenExpired) {
                dispatch({type: 'security-token-expired', payload: false})
            } else {
                submitRequest(null, {requestTypeList: ['metadata', 'audit-meta', 'partners-info', 'recent-partner-merchants', 'recent-loans']})
            }
            onClose()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [credentials]);

    return <Dialog
        PaperComponent={DraggableDialog}
        aria-labelledby="draggable-dialog-title"
        open={true}
        fullWidth={true}
    >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            Wisetack Console Login
        </DialogTitle>
        <DialogContent dividers={true}>
            <img style={{display: "block", height: "60px", marginLeft: "auto", marginRight: "auto"}} src={logo} alt="wisetack-logo"/>
            {fieldList.map((name) => (
                <FormInputCtr
                    key={name}
                    name={name}
                    formFields={formFields}
                    control={control}
                    disabled={requestInProgress}
                />
            ))}
            {loginRequired &&
                <div>Don't have an account? Please contact DevOps engineer.</div>
            }
        </DialogContent>
        <div style={{paddingTop: "10px", paddingLeft: "10px", paddingRight: "10px", paddingBottom: "5px"}}>
            {passwordResetRequired &&
                <Alert severity="warning" style={{marginBottom: "10px"}}>
                    Password reset required. Please enter new password and verification code we sent to your email.
                </Alert>
            }
            {newPasswordRequired &&
                <Alert severity="info" style={{marginBottom: "10px"}}>
                    Password change required. Please enter new password.
                </Alert>
            }
            {refreshingSession &&
                <Alert severity="success" style={{marginBottom: "10px"}}>
                    Refreshing session credentials.
                </Alert>
            }
            <ErrorMessage errorMessage={requestError}/>
            <Progress show={!!requestId && requestInProgress} requestId={requestId}/>
        </div>
        <DialogActions>
            { logoutEnabled && <Button disabled={requestInProgress} variant="contained" color="secondary" onClick={handleOnSignOut}>Sign out</Button> }
            <Button disabled={requestInProgress} variant="contained" color="primary" onClick={handleSubmit(handleOnSignIn)}>Sign in</Button>
            { !securityTokenExpired && logoutEnabled && <Button disabled={requestInProgress} variant="contained" onClick={onClose}>Close</Button> }
        </DialogActions>
    </Dialog>
}
