import React from 'react';
import {useFormik} from "formik";

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from "@material-ui/core";

import FormInput from "./FormInput";
import DraggableDialog from "./DraggableDialog";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import Alert from "./Alert";
import {userFormFieldList, userFormFields} from "../utils/constants";
import useApiRequest from "../hooks/useApiRequest";
import useApiRequestExecuted from "../hooks/useApiRequestExecuted";
import {fieldsInitialState} from "../utils/format";
import {getValidationSchema} from "../utils/validators";

const formFields = userFormFields;
const fieldList = userFormFieldList;
const updateRequestType = 'update-user';
const deleteRequestType = 'delete-user';

export default function EditUserDialog({data, onClose, submitRequest}) {
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();
    const userDeleted = useApiRequestExecuted('deleteUserRequestId', requestId);
    const userUpdated = useApiRequestExecuted('updateUserRequestId', requestId);

    const handleOnSave = (values) => {
        submitRequest(updateRequestType, values, newRequest());
    }

    const formik = useFormik({
        initialValues: fieldsInitialState(formFields, data),
        validationSchema: getValidationSchema(formFields),
        onSubmit: handleOnSave
    });

    const handleOnDelete = () => {
        submitRequest(deleteRequestType, formik.values, newRequest());
    }

    const UserUpdated = () => {
        if (userUpdated) {
            return <Alert severity="success">User updated</Alert>
        }
        return null;
    }

    const UserDeleted = () => {
        if (userDeleted) {
            return <Alert severity="success">User deleted</Alert>
        }
        return null;
    }

    return (
        <Dialog
            PaperComponent={DraggableDialog}
            aria-labelledby="draggable-dialog-title"
            open={true}
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                Edit User
            </DialogTitle>
            <DialogContent>
                {fieldList.map((name) => (
                    <FormInput
                        error={formik.touched[name] && formik.errors[name]}
                        onBlur={formik.handleBlur}
                        getEditValue={name => formik.values[name]}
                        disabled={userDeleted}
                        key={name}
                        name={name}
                        onChange={formik.handleChange}
                        formFields={formFields}
                    />
                ))}
                <ErrorMessage errorMessage={requestError}/>
                <Progress show={requestInProgress} requestId={requestId}/>
                <UserUpdated/>
                <UserDeleted/>
            </DialogContent>
            <DialogActions>
                <Button disabled={requestInProgress || userDeleted} variant="contained" color="secondary" onClick={handleOnDelete}>Delete</Button>
                <Button disabled={requestInProgress || userDeleted} variant="contained" color="primary" onClick={formik.handleSubmit}>Save</Button>
                <Button disabled={requestInProgress} variant="contained" onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}