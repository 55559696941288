import React from 'react';
import { connect } from "react-redux";

import AppLayout from "../components/AppLayout";
import PaymentLinkPanel from "../components/PaymentLinkPanel";
import LoanInfoPanel from "../components/LoanInfoPanel";
import LoanReviewPanel from "../components/LoanReviewPanel";
import PrequalsPanel from "../components/PrequalsPanel";
import BorrowerProfilePanel from "../components/BorrowerProfilePanel";

import {
    submitIpcRequest,
    openPaymentLink,
    openPrequalLink
} from "../store/actions/consoleActions";
import PrequalLinkPanel from "../components/PrequalLinkPanel";
import BorrowerCreditFilePanel from "../components/BorrowerCreditFilePanel";

function LoansPage(props) {
    return (
        <AppLayout title="Loan Applications">
            <PaymentLinkPanel submitRequest={props.submitIpcRequest} openPaymentLink={props.openPaymentLink}/>
            <LoanInfoPanel submitRequest={props.submitIpcRequest} openPaymentLink={props.openPaymentLink}/>
            <PrequalLinkPanel submitRequest={props.submitIpcRequest}/>
            <PrequalsPanel submitRequest={props.submitIpcRequest} openPrequalLink={props.openPrequalLink}/>
            <BorrowerProfilePanel submitRequest={props.submitIpcRequest}/>
            <BorrowerCreditFilePanel submitRequest={props.submitIpcRequest}/>
            <LoanReviewPanel submitRequest={props.submitIpcRequest}/>
        </AppLayout>
    )
}

export default connect(null, {
    submitIpcRequest,
    openPaymentLink,
    openPrequalLink
})(
    LoansPage
);
