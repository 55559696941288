import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function SubmitButton({ onClick, disabled }) {

    const classes = useStyles();

    return (

        <Button
            disabled={disabled}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={onClick}
        >
            Submit
        </Button>
    )
}