import React from 'react';
import {useSelector} from "react-redux";

import Grid from "@material-ui/core/Grid";

import JsonView from "./JsonView";
import FormInput from "./FormInput";
import ExPanel from "./ExPanel";
import SubmitButton from "./SubmitButton";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import useApiRequest from "../hooks/useApiRequest";
import useFields from "../hooks/useFields";

const formFields = {
    "CatalogName": {
        label: 'The name of the data catalog',
        initValue: 'AwsDataCatalog'
    },
    "DatabaseName": {
        label: 'The name of the database',
        initValue: 'wisetack_database'
    },
    "TableName": {
        label: 'The name of the table',
        initValue: 'wisetack'
    }
}

const fieldList = ["CatalogName", "DatabaseName", "TableName"];

const infoRequestType = 'table-metadata'

export default function TableMetadataPanel({submitRequest}) {
    const tableMetadata = useSelector(state => state.console.tableMetadata);
    const formsData = useSelector(state => state.console.formsData[infoRequestType]);
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();
    const [fields, handleOnChange] = useFields(formFields, formsData, fieldList);

    const handleRequest = () => {
        submitRequest(infoRequestType, fields, newRequest());
    }

    const TableMetadata = () => {
        if (!tableMetadata || requestInProgress) {
            return null;
        }
        return <JsonView data={tableMetadata} shouldExpandNode={(keyPath, data, level) => level < 4}/>
    }

    return (
        <ExPanel title="Data Lake Table Metadata">
            <Grid item xs={12}>
                <form>
                    {fieldList.map((name) => (
                        <FormInput
                            disabled={requestInProgress}
                            key={name}
                            name={name}
                            onChange={handleOnChange}
                            getEditValue={name => fields[name]}
                            formFields={formFields}
                        />
                    ))}
                    <SubmitButton onClick={handleRequest} disabled={requestInProgress}/>
                </form>
                <ErrorMessage errorMessage={requestError}/>
                <Progress show={requestInProgress} requestId={requestId}/>
                <TableMetadata/>
            </Grid>
        </ExPanel>
    )
}

