import log from "loglevel";
import {getIpcRenderer} from "../../utils/electron";

import {
    cancelBorrowerCreditFile,
    cancelBorrowerProfile,
    cloudWatchDescribeQueries,
    cloudWatchInsightsQuery,
    cloudWatchLogGroups,
    cognitoSignIn,
    createAuditTrail,
    createLegacySignup,
    createPartner,
    createPrequalLinkManual,
    createUser,
    dataLakeQuery,
    decryptWithVaultLambda,
    deletePartner,
    deleteUser,
    documentAccept,
    encryptWithVaultLambda,
    getAlloyData,
    getAuditTrail,
    getBorrowerCreditFileList,
    getBorrowerProfileList,
    getCRMTaskId,
    getDataLakeTableMetadata,
    getDocumentContent,
    getDocumentsInfo,
    getIndustryList,
    getLoanInfo,
    getMerchantAccessToken,
    getMerchantsInfo,
    getMetaData,
    getPartnerAccessToken,
    getPartnersInfo,
    getPrequalsList,
    getRecentLoans,
    getRecentPartnerMerchants,
    getSignupInfo,
    getUsersInfo,
    invokeDisbursement,
    loanLockout,
    metaAuditTrail,
    prequalsExpire, putItemToDB,
    queryDynamoDB,
    refreshCognitoSession,
    removeBorrowerCreditFile,
    removeBorrowerProfile,
    removeIndustry,
    restoreBorrowerCreditFile,
    restoreBorrowerProfile,
    reviewMerchantLambda,
    signupRepair,
    updateIndustry,
    updateMerchant,
    updatePartner,
    updateUser,
    usersLockout
} from "./aws";

import {
    createPaymentLinkApi,
    createPrequalLinkApi,
    createSignupApi,
    createTransactionApi, getApiUrl,
    getProfileName, getSignupInfoApi,
    getTransactionsInfoApi,
    reviewLoanApplicationApi,
    reviewMerchantApi
} from "./api";

const ipcRenderer = getIpcRenderer();
log.setDefaultLevel('DEBUG')

const reviewMerchant = async (data) => {
    const response = await reviewMerchantApi(data);
    await createAuditTrail('REVIEW_MERCHANT', 'MERCHANT', data.id, {
        request: JSON.stringify(data),
        response: JSON.stringify(response)
    })
    return response;
}

const reviewLoan = async ({loanApplicationId, outcome}) => {
    const taskId = await getCRMTaskId(loanApplicationId);
    const response = await reviewLoanApplicationApi({taskId, outcome})
    await createAuditTrail('REVIEW_LOAN_APPLICATION', 'LOAN_APPLICATION', loanApplicationId, {
        taskId,
        outcome,
        response: JSON.stringify(response)
    })
    return response;
}

const getTransactionsInfo = async (data) => {
    if (!data.merchantId) {
        throw new Error('Merchant ID not specified to get transactions.')
    }
    const accessToken = await getMerchantAccessToken(data.merchantId);
    await createAuditTrail('GET_MERCHANT_TRANSACTIONS','MERCHANT', data.merchantId, {request: JSON.stringify(data)})
    return await getTransactionsInfoApi(data, accessToken);
}

const getSignupListInfo = async (data) => {
    if (!data.accountId) {
        throw new Error('Account ID not specified to get signup info.')
    }
    const accessToken = await getPartnerAccessToken(data.accountId);
    return await getSignupInfoApi(data, accessToken);
}

const createPaymentLink = async (data) => {
    const merchantId = data.merchantId;
    if (!merchantId) {
        throw new Error('Merchant ID not specified to create payment link.')
    }
    const accessToken = await getMerchantAccessToken(merchantId);
    const response = await createPaymentLinkApi(data, accessToken);
    await createAuditTrail('CREATE_PAYMENT_LINK', 'LOAN_APPLICATION', response.transactionId, {
        request: JSON.stringify(data),
        response: JSON.stringify(response)
    })
    return response;
}

const createPrequalLink = async (data) => {
    if (!data.merchantId) {
        throw new Error('Merchant ID not specified to create prequal link.')
    }
    const accessToken = await getMerchantAccessToken(data.merchantId);
    const response = await createPrequalLinkApi(data, accessToken);
    await createAuditTrail('CREATE_PREQUAL_LINK', 'MERCHANT', data.merchantId, {
        request: JSON.stringify(data),
        response: JSON.stringify(response)
    })
    return response;
}

const createTransaction = async (data) => {
    const merchantId = data.id;
    if (!merchantId) {
        throw new Error('Merchant ID not specified to create transaction.')
    }
    const accessToken = await getMerchantAccessToken(merchantId);
    const response = await createTransactionApi(data, accessToken);
    await createAuditTrail('CREATE_TRANSACTION', 'MERCHANT', merchantId, {
        request: JSON.stringify(data),
        response: JSON.stringify(response)
    })
    if (response && response.initToken) {
        const profile = await getProfileName();
        const protocol = (profile === 'wisetack' || profile === 'wisetack_sec') ? 'https' : 'http';
        response.paymentLink = `${protocol}://${profile}.us/#/${response.initToken}`;
        response.merchantId = merchantId;
    }
    return response;
}

const createSignup = async (data) => {
    const accessToken = await getPartnerAccessToken(data.accountId);
    const requestData = {
        ...data
    }
    delete requestData['accountId'];
    if (requestData.industry) {
        requestData.business = {industry: requestData.industry}
        delete requestData['industry'];
    }
    const response = await createSignupApi(requestData, accessToken);
    await createAuditTrail('CREATE_SIGNUP', 'MERCHANT', response.merchantId, {
        request: JSON.stringify(requestData),
        response: JSON.stringify(response)
    })
    return response;
}

const updateAndGetIndustry = async (data) => {
    await updateIndustry(data)
    return await getIndustryList(data)
}

const removeAndGetIndustry = async (data) => {
    await removeIndustry(data)
    return await getIndustryList({limit: 100})
}

const replaceField = (item, name) => {
    const lName = name.toLowerCase();
    if (item[lName]) {
        const val = item[lName]
        delete item[lName]
        item[name] = val
    }
}

const convertRecord = (item, name) => {
    delete item["aws_rep_updatetime"]
    replaceField(item, 'hashKey')
    replaceField(item, 'rangeKey')
    replaceField(item, 'mirrorHashKey')
    replaceField(item, 'mirrorRangeKey')
    replaceField(item, 'gsi1HashKey')
    replaceField(item, 'gsi1RangeKey')
    replaceField(item, 'gsi2HashKey')
    replaceField(item, 'gsi2RangeKey')
    replaceField(item, 'gsi3HashKey')
    replaceField(item, 'gsi3RangeKey')
    replaceField(item, 'gsi4HashKey')
    replaceField(item, 'gsi4RangeKey')
    replaceField(item, 'gsi5HashKey')
    replaceField(item, 'gsi5RangeKey')
    replaceField(item, 'gsi6HashKey')
    replaceField(item, 'gsi6RangeKey')
    replaceField(item, 'gsi7HashKey')
    replaceField(item, 'gsi7RangeKey')
    replaceField(item, 'gsi8HashKey')
    replaceField(item, 'gsi8RangeKey')
    replaceField(item, 'gsi9HashKey')
    replaceField(item, 'gsi9RangeKey')
    replaceField(item, 'gsi10HashKey')
    replaceField(item, 'gsi10RangeKey')
    if (name && item[name.toLowerCase()]) {
        const data = JSON.stringify(item[name.toLowerCase()])
        delete item[name.toLowerCase()]
        item[name] = data
    }
    return item
}

const importAccounts = async (data) => {
    if (!data || data.length === 0) {
        throw new Error("No data to import accounts.")
    }
    const keyEncrypted = await encryptWithVaultLambda('test_secret')
    for (const element of data) {
        let item = JSON.parse(JSON.stringify(element));
        if (item.account) {
            if (item.account.keyEncrypted) {
                item.account.keyEncrypted = keyEncrypted
            }
            if (item.account.callbackUrl) {
                item.account.callbackUrl = await getApiUrl('testwebhook')
            }
            await putItemToDB(convertRecord(item, 'account'))
        }
    }
}

const encryptToken = async (obj, fieldName) => {
    if (!obj || !obj[fieldName]) {
        return;
    }
    if (fieldName === 'emailEncrypted') {
        await encryptWithVaultLambda('test+encrypted@wisetack.com', obj[fieldName])
    } else if (fieldName === 'phoneNumberEncrypted' || fieldName === 'numberEncrypted') {
        await encryptWithVaultLambda('+15555555555', obj[fieldName])
    } else if (fieldName === 'ssn4Encrypted') {
        await encryptWithVaultLambda('4444', obj[fieldName])
    } else if (fieldName === 'ssnEncrypted') {
        await encryptWithVaultLambda('444444444', obj[fieldName])
    } else if (fieldName === 'federalEINEncrypted') {
        await encryptWithVaultLambda('999999999', obj[fieldName])
    } else if (fieldName === 'bankAccountEncrypted') {
        await encryptWithVaultLambda('1111222233330000', obj[fieldName])
    } else if (fieldName === 'dobEncrypted') {
        await encryptWithVaultLambda('1980-01-01', obj[fieldName])
    } else {
        await encryptWithVaultLambda(fieldName, obj[fieldName])
    }
}

const importMerchants = async (data) => {
    if (!data || data.length === 0) {
        throw new Error("No data to import merchants.")
    }
    for (const element of data) {
        let item = JSON.parse(JSON.stringify(element));
        if (item.merchant) {
            await encryptToken(item.merchant, 'emailEncrypted')
            await encryptToken(item.merchant, 'phoneNumberEncrypted')
            await encryptToken(item.merchant, 'businessAddressEncrypted')
            await encryptToken(item.merchant, 'federalEINEncrypted')
            await encryptToken(item.merchant, 'bankAccountEncrypted')
            await encryptToken(item.merchant, 'bankAccountNameEncrypted')
            if (item.merchant.owners && item.merchant.owners.length > 0) {
                for (const owner of item.merchant.owners) {
                    await encryptToken(owner, 'firstNameEncrypted')
                    await encryptToken(owner, 'lastNameEncrypted')
                    await encryptToken(owner, 'mobileNumberEncrypted')
                    await encryptToken(owner, 'emailEncrypted')
                    await encryptToken(owner, 'homeAddressEncrypted')
                    await encryptToken(owner, 'dobEncrypted')
                    await encryptToken(owner, 'ssn4Encrypted')
                    await encryptToken(owner, 'ssnEncrypted')
                }
            }
            await putItemToDB(convertRecord(item, 'merchant'))
        }
    }
}

const importSignups = async (data) => {
    if (!data || data.length === 0) {
        throw new Error("No data to import signups.")
    }
    for (const element of data) {
        let item = JSON.parse(JSON.stringify(element));
        if (item.signup) {
            if (item.signup.callbackUrl) {
                item.signup.callbackUrl = await getApiUrl('testwebhook')
            }
            if (item.signup.initiator) {
                await encryptToken(item.signup.initiator, 'firstNameEncrypted')
                await encryptToken(item.signup.initiator, 'lastNameEncrypted')
                await encryptToken(item.signup.initiator, 'mobileNumberEncrypted')
                await encryptToken(item.signup.initiator, 'emailEncrypted')
            }
            await putItemToDB(convertRecord(item, 'signup'))
        }
    }
}

const encryptPi = async (data) => {
    if (!data) {
        return
    }
    if (data.addressList) {
        for (const address of data.addressList) {
            await encryptToken(address, 'streetAddress1Encrypted')
        }
    }
    if (data.emailList) {
        for (const address of data.emailList) {
            await encryptToken(address, 'addressEncrypted')
        }
    }
    if (data.phoneList) {
        for (const phone of data.phoneList) {
            await encryptToken(phone, 'numberEncrypted')
        }
    }
    await encryptToken(data, 'firstNameEncrypted')
    await encryptToken(data, 'lastNameEncrypted')
    await encryptToken(data, 'dobEncrypted')
    await encryptToken(data, 'ssn4Encrypted')
    await encryptToken(data, 'ssnEncrypted')
}

const importLoanOffers = async (data) => {
    if (!data || data.length === 0) {
        throw new Error("No data to import loan offers.")
    }
    for (const element of data) {
        let item = JSON.parse(JSON.stringify(element));
        if (item.loanoffer) {
            await putItemToDB(convertRecord(item, 'loanOffer'))
        }
    }
}

const importLoans = async (data) => {
    if (!data || data.length === 0) {
        throw new Error("No data to import loans.")
    }
    for (const element of data) {
        let item = JSON.parse(JSON.stringify(element));
        if (item.loanapplication) {
            if (item.loanapplication.callbackUrl) {
                item.loanapplication.callbackUrl = await getApiUrl('testwebhook')
            }
            await encryptToken(item.loanapplication, 'mobileNumberEncrypted')
            await encryptToken(item.loanapplication, 'ssnEncrypted')
            await encryptPi(item.loanapplication.borrowerPersonalInformationProvidedByMerchant)
            await encryptPi(item.loanapplication.borrowerPersonalInformationProvidedByBorrower)
            await encryptPi(item.loanapplication.borrowerPersonalInformationProvidedByBureau)
            await putItemToDB(convertRecord(item, 'loanApplication'))
        }
    }
}

const importPrequals = async (data) => {
    if (!data || data.length === 0) {
        throw new Error("No data to import prequals.")
    }
    for (const element of data) {
        let item = JSON.parse(JSON.stringify(element));
        if (item.prequalapplication) {
            if (item.prequalapplication.callbackUrl) {
                item.prequalapplication.callbackUrl = await getApiUrl('testwebhook')
            }
            await encryptToken(item.prequalapplication, 'mobileNumberEncrypted')
            await encryptToken(item.prequalapplication, 'ssnEncrypted')
            await encryptPi(item.prequalapplication.borrowerPersonalInformationProvidedByMerchant)
            await encryptPi(item.prequalapplication.borrowerPersonalInformationProvidedByBorrower)
            await encryptPi(item.prequalapplication.borrowerPersonalInformationProvidedByBureau)
            await putItemToDB(convertRecord(item, 'prequalApplication'))
        }
    }
}

const openDocument = async (data) => {
    const response = await getDocumentContent(data);
    if (data.open && response.documents && response.documents.length > 0) {
        const document = response.documents[0];
        const id = document.id || document.entityId;
        const pdfBuffer = Buffer.from(document.content, 'base64')
        if (ipcRenderer) {
            await ipcRenderer.invoke('open-pdf-window', {id, pdfBuffer})
        } else {
            window.open(URL.createObjectURL(new Blob([pdfBuffer], {
                type: "application/pdf"
            })),'_blank')
        }
    } else if (response.errorMessage) {
        throw new Error(response.errorMessage)
    } else {
        throw new Error("Document not found in lambda response.")
    }
    return response
}

const openPaymentLink = async ({paymentLink, shortId, initToken}) => {
    if (shortId && !initToken && !paymentLink) {
        const loanInfo = await getLoanInfo({loanApplicationId: shortId, dataTypes: ['LOAN'], decrypt: false});
        if (!loanInfo || !loanInfo.loanApplication || !loanInfo.loanApplication.initToken) {
            throw new Error(`Loan application [${shortId}] not found.`);
        }
        initToken = loanInfo.loanApplication.initToken;
    }
    if (initToken && !paymentLink) {
        const profile = await getProfileName();
        const protocol = (profile === 'wisetack' || profile === 'wisetack_sec') ? 'https' : 'http';
        paymentLink = `${protocol}://${profile}.us/#/${initToken}`
    }
    if (!paymentLink) {
        throw new Error(`Payment Link undefined to open.`);
    }
    await createAuditTrail('OPEN_PAYMENT_LINK', 'LOAN_APPLICATION', null, {paymentLink})
    if (ipcRenderer) {
        await ipcRenderer.invoke('open-payment-link-window', paymentLink)
    } else {
        window.open(paymentLink, '_blank').focus();
    }
}

const openPrequalLink = async (data) => {
    let prequalLink = data.prequalLink;
    if (!prequalLink) {
        const link = data.link;
        if (link) {
            const profile = await getProfileName();
            const protocol = (profile === 'wisetack' || profile === 'wisetack_sec') ? 'https' : 'http';
            prequalLink = `${protocol}://${profile}.us/#${link}`
        }
    }
    if (!prequalLink) {
        throw new Error(`Prequal Link undefined to open.`);
    }
    await createAuditTrail('OPEN_PREQUAL_LINK', 'PREQUAL_APPLICATION', null, {prequalLink})
    if (ipcRenderer) {
        await ipcRenderer.invoke('open-signup-window', prequalLink)
    } else {
        window.open(prequalLink, '_blank').focus();
    }
}

const openSignupLink = async ({signupLink, createUrl}) => {
    if (createUrl) {
        const profile = await getProfileName();
        const protocol = (profile === 'wisetack' || profile === 'wisetack_sec') ? 'https' : 'http';
        signupLink = `${protocol}://signup.${profile}.us/#/${signupLink}`;
    }
    await createAuditTrail('OPEN_SIGNUP_LINK', 'SIGNUP', null, {signupLink})
    if (ipcRenderer) {
        await ipcRenderer.invoke('open-signup-window', signupLink)
    } else {
        window.open(signupLink, '_blank').focus();
    }
}

const openMerchantPortal = async () => {
    const profile = await getProfileName();
    const protocol = (profile === 'wisetack' || profile === 'wisetack_sec') ? 'https' : 'http';
    const url = `${protocol}://business.${profile}.us`;
    if (ipcRenderer) {
        await ipcRenderer.invoke('open-payment-link-window', url)
    } else {
        window.open(url, '_blank').focus();
    }
}

const openExternal = async ({url}) => {
    if (ipcRenderer) {
        await ipcRenderer.invoke('open-external', {url})
    } else {
        window.open(url, '_blank').focus();
    }
}

const requestMap = {
    'cognito-sign-in': cognitoSignIn,
    'refresh-cognito-session': refreshCognitoSession,
    'metadata': getMetaData,
    'vault-encrypt': encryptWithVaultLambda,
    'vault-decrypt': decryptWithVaultLambda,
    'get-partner-access-token': getPartnerAccessToken,
    'get-merchant-access-token': getMerchantAccessToken,
    'recent-partner-merchants': getRecentPartnerMerchants,
    'recent-loans': getRecentLoans,
    'partners-info': getPartnersInfo,
    'create-partner': createPartner,
    'update-partner': updatePartner,
    'delete-partner': deletePartner,
    'merchants-info': getMerchantsInfo,
    'reload-merchant': getMerchantsInfo,
    'update-merchant': updateMerchant,
    'audit-logs': getAuditTrail,
    'audit-trail': getAuditTrail,
    'loan-info': getLoanInfo,
    'signup-info': getSignupInfo,
    'create-signup': createSignup,
    'signup-repair': signupRepair,
    'payment-link': createPaymentLink,
    'create-transaction': createTransaction,
    'loan-review': reviewLoan,
    'review-merchant': reviewMerchant,
    'transactions-info': getTransactionsInfo,
    'signup-list-info': getSignupListInfo,
    'get-alloy-data': getAlloyData,
    'create-prequal-link-api': createPrequalLink,
    'create-prequal-link': createPrequalLinkManual,
    'prequals-list': getPrequalsList,
    'prequals-expire': prequalsExpire,
    'borrower-credit-file-list': getBorrowerCreditFileList,
    'borrower-credit-file-cancel': cancelBorrowerCreditFile,
    'borrower-credit-file-restore': restoreBorrowerCreditFile,
    'borrower-credit-file-remove': removeBorrowerCreditFile,
    'invoke-disbursement': invokeDisbursement,
    'borrower-profile-list': getBorrowerProfileList,
    'borrower-profile-cancel': cancelBorrowerProfile,
    'borrower-profile-restore': restoreBorrowerProfile,
    'borrower-profile-remove': removeBorrowerProfile,
    'open-payment-link': openPaymentLink,
    'open-prequal-link': openPrequalLink,
    'open-signup': openSignupLink,
    'open-merchant-portal': openMerchantPortal,
    'query-dynamodb': queryDynamoDB,
    'query-dynamodb-record': queryDynamoDB,
    'create-legacy-signup': createLegacySignup,
    'documents-info': getDocumentsInfo,
    'document-content': openDocument,
    'document-accept': documentAccept,
    'users-info': getUsersInfo,
    'update-user': updateUser,
    'create-user': createUser,
    'delete-user': deleteUser,
    'users-lockout-info': usersLockout,
    'users-lockout-update': usersLockout,
    'loan-lockout-update': loanLockout,
    'open-external': openExternal,
    'audit-meta': metaAuditTrail,
    'cloudwatch-insights-query': cloudWatchInsightsQuery,
    'cloudwatch-log-groups': cloudWatchLogGroups,
    'cloudwatch-recent-queries': cloudWatchDescribeQueries,
    'data-lake-query': dataLakeQuery,
    'table-metadata': getDataLakeTableMetadata,
    'industry-list': getIndustryList,
    'industry-update': updateAndGetIndustry,
    'industry-remove': removeAndGetIndustry,
    'import-accounts': importAccounts,
    'import-merchants': importMerchants,
    'import-signups': importSignups,
    'import-loans': importLoans,
    'import-prequals': importPrequals,
    'import-loan-offers': importLoanOffers,
    'review-merchant-lambda': reviewMerchantLambda
}

export const invokeRequest = async (requestType, requestData) => {
    if (requestMap.hasOwnProperty(requestType)) {
        log.debug(`-<R>- ${requestType}`)
        return await requestMap[requestType](requestData);
    } else {
        log.debug(`->R<- ${requestType}`)
        if (!ipcRenderer) {
            throw new Error(`ipcRenderer not defined to invoke [${requestType}].`)
        }
        return await ipcRenderer.invoke(requestType, requestData);
    }
}
