import React from 'react';
import { connect } from "react-redux";

import AppLayout from "../components/AppLayout";
import { submitIpcRequest } from "../store/actions/consoleActions";
import SplunkSearchPanel from "../components/SplunkSearchPanel";


function SplunkPage(props) {
    return (
        <AppLayout title="Splunk">
            <SplunkSearchPanel
                submitRequest={props.submitIpcRequest}
            />
        </AppLayout>
    )
}

export default connect(null, {submitIpcRequest})(
    SplunkPage
);
