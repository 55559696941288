import React from "react";
import {useDispatch, useSelector} from "react-redux";

import useApiRequest from "../hooks/useApiRequest";
import ExPanel from "./ExPanel";
import {Button, DialogActions, Grid} from "@material-ui/core";
import FormInput from "./FormInput";
import ErrorMessage from "./ErrorMessage";
import Alert from "./Alert";
import Progress from "./Progress";
import {
    accountMaskValidator,
    bankAccountValidator,
    bankRoutingNumberValidator,
    getValidationSchema
} from "../utils/validators";
import {accountSubTypes} from "../utils/constants";
import {autoFill, fieldsInitialState, getFieldList} from "../utils/format";
import {useFormik} from "formik";

const formFields = {
    "merchantId": {
        label: 'Merchant ID',
        initValue: "",
        order: 1
    },
    "signupId": {
        label: 'Signup ID',
        initValue: "",
        order: 2
    },
    "bankRoutingNumber": {
        label: 'Bank Routing Number',
        validator: bankRoutingNumberValidator,
        initValue: '',
        order: 3
    },
    "paymentAccountMask": {
        label: 'Bank Account Mask',
        validator: accountMaskValidator,
        initValue: '',
        order: 4
    },
    "bankAccount": {
        label: 'Bank Account Number',
        validator: bankAccountValidator,
        initValue: '',
        order: 5
    },
    "bankAccountName": {
        label: 'Bank Account Name',
        initValue: '',
        order: 6
    },
    "bankAccountSubType": {
        label: 'Bank Account SubType',
        type: 'select',
        data: accountSubTypes,
        initValue: '',
        order: 7
    },
    "strictMode": {
        label: 'Repair only EXECUTIVE_ADDED and PAYMENT_ACCOUNT_SUBMITTED statuses',
        type: 'switch',
        initValue: true,
        order: 8
    },
    "cleanMode": {
        label: 'Only clean data without running rules engine, after that user can try to link bank account again and finish signup using UI',
        type: 'switch',
        initValue: false,
        order: 9
    }
};

const fieldList = getFieldList(formFields);

const requestType = 'signup-repair';

const SignupRepairInfo = ({signupRepairInfo}) => {
    if (signupRepairInfo && signupRepairInfo.infoMessage) {
        return <Alert severity="info">{signupRepairInfo.infoMessage}</Alert>
    }
    return null;
}

export default function SignupRepairPanel({submitRequest}) {
    const signupRepairInfo = useSelector(state => state.console.signupRepairInfo);
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();

    const dispatch = useDispatch();

    const handleSubmit = (values) => {
        dispatch({type: "clean-signup-repair"})
        submitRequest(requestType, values, newRequest());
    }

    const formik = useFormik({
        initialValues: fieldsInitialState(formFields),
        validationSchema: getValidationSchema(formFields),
        onSubmit: handleSubmit
    });

    const handleOnChange = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        e.target.value = autoFill(name, val, formFields);
        formik.handleChange(e)
    }

    return (
        <ExPanel title="Merchant Signup Repair">
            <Grid item xs={12}>
                <div>
                    Please specify merchant ID or signup ID to repair.<br/>
                    Also you can provide bank account data (not mandatory).<br/>
                </div>
                <div style={{color: "red"}}>
                    Don't use this form if you only need to update bank account data, use merchant update form instead.<br/>
                </div>
                <form>
                    {fieldList.map((name) => (
                        <FormInput
                            disabled={requestInProgress}
                            key={name}
                            name={name}
                            formFields={formFields}
                            error={formik.touched[name] && formik.errors[name]}
                            onBlur={formik.handleBlur}
                            getEditValue={name => formik.values[name]}
                            onChange={handleOnChange}
                        />
                    ))}
                </form>
                {!formik.values['strictMode'] &&
                    <Alert severity="warning">CAUTION! If application already submitted and evaluated, previous underwriting data will be removed and merchant application will be re-evaluated.</Alert>
                }
                <DialogActions>
                    <Button disabled={requestInProgress} variant="contained" color="primary" onClick={formik.handleSubmit}>Submit</Button>
                </DialogActions>
                <ErrorMessage errorMessage={requestError}/>
                <Progress show={requestInProgress} requestId={requestId}/>
                {!requestInProgress && <SignupRepairInfo signupRepairInfo={signupRepairInfo}/>}
            </Grid>
        </ExPanel>
    )
}
