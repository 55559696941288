import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: '16px'
    },
}));

export default function Title({children}) {
    const classes = useStyles();

    return (
        <Typography component="h2" variant="h6" color="primary" gutterBottom className={classes.title}>
            {children}
        </Typography>
    )
}