import 'date-fns';
import React from 'react';
import {useSelector} from "react-redux";

import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";
import {Button, DialogActions} from "@material-ui/core";
import {DataGrid} from "@mui/x-data-grid";
import Link from "@material-ui/core/Link";

import ExPanel from "./ExPanel";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import useApiRequest from "../hooks/useApiRequest";
import JsonView from "./JsonView";
import JsonSwitch from "./JsonSwitch";
import {phoneNumberPattern} from "../utils/validators";
import {useForm} from "react-hook-form";
import {FormInputCtr} from "./FormInputCtr";

const BorrowerProfileList = ({columns, loading, jsonView, data}) => {
    if (!data) {
        return null;
    }
    if (jsonView) {
        return <JsonView data={data} shouldExpandNode={(keyPath, data, level) => level < 3}/>
    }
    return <div style={{height: 400, width: '100%'}}>
        <DataGrid
            getRowId={(row) => row.createdAt}
            rows={data.profileList}
            hideFooterSelectedRowCount={true}
            autoPageSize={true}
            columns={columns}
            loading={loading}
            density='compact'
        />
    </div>
}

const formFields = {
    "mobileNumber": {
        label: 'Mobile Number',
        type: 'phone',
        rules: {
            required: 'Mobile number required',
            pattern: phoneNumberPattern
        },
        initValue: ''
    },
    "actionDate": {
        label: 'Date From',
        type: 'date',
        initValue: null
    },
    "limit": {
        label: 'Limit',
        initValue: '20'
    },
    "cancelPrequalApplication": {
        label: 'Cancel prequal applications on profile cancel',
        type: 'switch',
        initValue: true
    },
    "cancelLoanApplication": {
        label: 'Cancel loan applications on profile cancel',
        type: 'switch',
        initValue: true
    }
}

const fieldList = [
    "mobileNumber",
    "actionDate",
    "limit",
    "cancelPrequalApplication",
    "cancelLoanApplication"
]

const getRequestType = 'borrower-profile-list';
const cancelRequestType = 'borrower-profile-cancel';
const restoreRequestType = 'borrower-profile-restore';
const removeRequestType = 'borrower-profile-remove';

export default function BorrowerProfilePanel({submitRequest}) {
    const formsData = useSelector(state => state.console.formsData[getRequestType]);
    const settings = useSelector(state => state.console.settings);
    const notProduction = settings ? settings.profile !== 'wisetack' : false;

    const borrowerProfileList = useSelector(state => state.console.borrowerProfileList);
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();

    const [jsonView, setJsonView] = React.useState(false);

    const { handleSubmit, control, getValues } = useForm({
        defaultValues: {
            ...formsData
        }
    });

    const onSubmit = (values) => {
        submitRequest(getRequestType, values, newRequest());
    }

    const nameCellRenderer = ({row}) => {
        const pi = row.borrowerPersonalInformationProvidedByBorrower;
        if (!pi) {
            return '';
        }
        return pi.firstNameEncrypted + ' ' + pi.lastNameEncrypted;
    }

    const cancelCellRenderer = ({row, value}) => {
        if (value) {
            return <>
                <Link title="Restore profile" href="#" color="primary" onClick={(e) => {
                    e.preventDefault();
                    submitRequest(restoreRequestType, {
                        mobileNumber: row.mobileNumberEncrypted,
                        actionDate: row.createdAt,
                        limit: getValues('limit')
                    }, newRequest());
                }}>
                    {value}
                </Link>
            </>
        }
        return <>
            <Link href="#" color="primary" onClick={(e) => {
                e.preventDefault();
                submitRequest(cancelRequestType, {
                    mobileNumber: row.mobileNumberEncrypted,
                    actionDate: row.createdAt,
                    limit: getValues('limit'),
                    cancelPrequalApplication: getValues('cancelPrequalApplication'),
                    cancelLoanApplication: getValues('cancelLoanApplication')
                }, newRequest());
            }}>
                CANCEL
            </Link>
            { notProduction &&
                <Link style={{marginLeft: "10px"}} href="#" color="secondary" onClick={(e) => {
                    e.preventDefault();
                    submitRequest(removeRequestType, {
                        mobileNumber: row.mobileNumberEncrypted,
                        actionDate: row.createdAt,
                        limit: getValues('limit')
                    }, newRequest());
                }}>
                    REMOVE
                </Link>
            }
        </>
    }

    const columns = [
        {field: 'mobileNumberEncrypted', headerName: 'Phone', width: 150},
        {field: 'name', headerName: 'Name', width: 200, renderCell: nameCellRenderer},
        {field: 'createdAtUT', headerName: 'Created', width: 170},
        {field: 'canceledAt', headerName: 'Canceled', width: 170, renderCell: cancelCellRenderer},
    ]

    return (
        <ExPanel title="Borrower Profile">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid item xs={12}>
                    <form>
                        {fieldList.map((name) => (
                            <FormInputCtr
                                key={name}
                                name={name}
                                formFields={formFields}
                                control={control}
                                disabled={requestInProgress}
                            />
                        ))}
                    </form>
                    <DialogActions>
                        <Button disabled={requestInProgress} variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>Submit</Button>
                    </DialogActions>
                    <ErrorMessage errorMessage={requestError}/>
                    <Progress show={requestInProgress} requestId={requestId}/>
                    <JsonSwitch hide={!borrowerProfileList} jsonView={jsonView} setJsonView={setJsonView}/>
                    <BorrowerProfileList
                        data={borrowerProfileList}
                        jsonView={jsonView}
                        loading={requestInProgress}
                        columns={columns}
                    />
                </Grid>
            </MuiPickersUtilsProvider>
        </ExPanel>
    )
}