import {useSelector} from "react-redux";
import {isElectron} from "../utils/electron";
import {get} from "lodash-es"
import {getUser} from "../utils/localStorage";

export default function useCredentials() {
    let username;
    let profile;
    let loginRequired;
    let tokenExpired;
    let savedUser;

    const settings = useSelector(state => state.console.settings);
    const cognitoSignIn = useSelector(state => state.console.cognitoSignIn);
    const securityTokenExpired = useSelector(state => state.console.securityTokenExpired);

    if (isElectron()) {
        loginRequired = false;
        tokenExpired = false;
        profile = get(settings, 'profile')
        username = get(settings, 'username')
    } else {
        loginRequired = !cognitoSignIn || !cognitoSignIn.credentials
        tokenExpired = securityTokenExpired
        profile = process.env.REACT_APP_AWS_PROFILE
        username = get(cognitoSignIn, 'accessToken.payload.username')
        savedUser = getUser();
        if (!username && savedUser) {
            username = savedUser.email
        }
    }
    return {profile, username, loginRequired, tokenExpired, savedUser}
}