import React from 'react';
import { connect } from "react-redux";
import { v4 as uuid } from 'uuid';

import JSONTree from "react-json-tree";
import Grid from '@material-ui/core/Grid';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import {InputAdornment} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import AppLayout from "../components/AppLayout";
import Panel from '../components/Panel';
import SubmitButton from "../components/SubmitButton";
import ExPanel from "../components/ExPanel";
import ErrorMessage from "../components/ErrorMessage";
import Progress from "../components/Progress";
import {jsonTreeTheme, VERSION} from '../utils/constants';
import { submitSettings, getSettings } from "../store/actions/consoleActions";
import Alert from "../components/Alert";
import {isElectron} from "../utils/electron";

function SettingsPage(props) {
    const [profiles, setProfiles] = React.useState(null);
    const [profile, setProfile] = React.useState('');
    const [region, setRegion] = React.useState('');
    const [dataLakeKey, setDataLakeKey] = React.useState('');
    const [dataLakeSecret, setDataLakeSecret] = React.useState('');
    const [showSecret, setShowSecret] = React.useState(false);
    const [useExternalBrowser, setUseExternalBrowser] = React.useState(false);
    const [externalBrowserPath, setExternalBrowserPath] = React.useState('');

    const [settingsRequestId, setSettingsRequestId] = React.useState('');
    const desktop = isElectron();

    const retrieveSettings = props.getSettings;

    React.useEffect(() => {
        if (!desktop) {
            return;
        }
        function handleSettings(settings) {
            setProfiles(settings.profiles);
            setProfile(settings.profile);
            setRegion(settings.region);
            setDataLakeKey(settings.dataLakeKey);
            setDataLakeSecret(settings.dataLakeSecret);
            setUseExternalBrowser(settings.useExternalBrowser);
            setExternalBrowserPath(settings.externalBrowserPath);
            return settings
        }
        const id = uuid();
        setSettingsRequestId(id);
        retrieveSettings(id, handleSettings);
    }, [desktop, retrieveSettings]);

    const handleSubmit = () => {
        const id = uuid();
        setSettingsRequestId(id);
        props.submitSettings({profile, region, dataLakeKey, dataLakeSecret, useExternalBrowser, externalBrowserPath, id});
    }

    const handleClickShowSecret = () => {
        setShowSecret(!showSecret);
    }

    const Settings = () => {
        if (props.settings && !props.apiRequests[settingsRequestId]) {
            return <JSONTree
                    data={props.settings}
                    theme={jsonTreeTheme}
                    shouldExpandNode={() => {
                        return true;
                    }}
                />
        }
        return null;
    }

    const UseExternalBrowser = () => {
        return (
            <span style={{marginRight: "20px"}}><FormControlLabel
                control={
                    <Switch
                        checked={useExternalBrowser}
                        onChange={() => setUseExternalBrowser(!useExternalBrowser)}
                        color="primary"
                    />
                }
                label="Use external browser to open links"
            /></span>
        )
    }

    const SelectRegion = () => {
        return <FormControl variant="outlined" fullWidth={true}>
            <InputLabel id="select-region-label">Region</InputLabel>
            <Select
                labelId="select-region-label"
                id="select-region"
                value={region}
                margin="dense"
                onChange={e => setRegion(e.target.value)}
                label="Region"
            >
                <MenuItem value={"us-east-1"}>us-east-1</MenuItem>
                <MenuItem value={"us-west-2"}>us-west-2</MenuItem>
            </Select>
        </FormControl>
    }

    const SelectProfile = () => {
        if (profiles && profiles.length === 0) {
            return <Alert style={{marginBottom: "15px"}} severity="error">
                No Wisetack AWS profiles found. Please fix your AWS SDK configuration file ~/.aws/credentials and restart application.
            </Alert>
        }
        return <FormControl style={{marginBottom: "15px"}} variant="outlined" fullWidth={true}>
            <InputLabel id="select-profile-label">Profile</InputLabel>
            <Select
                labelId="select-profile-label"
                id="select-profile"
                value={profile}
                margin="dense"
                onChange={e => setProfile(e.target.value)}
                label="Profile"
            >
                {profiles && profiles.map((item) => (
                    <MenuItem key={item} value={item}>{item}</MenuItem>
                ))}
            </Select>
        </FormControl>
    }

    const DataLakeKey = () => {
        return <TextField
            label="Production Access Key ID"
            variant="outlined"
            margin="normal"
            size="small"
            fullWidth
            value={dataLakeKey}
            onChange={e => setDataLakeKey(e.target.value)}
        />
    }

    const ExternalBrowserPath = () => {
        return <TextField
            disabled={!useExternalBrowser}
            label="External Browser Path (user's default if empty)"
            variant="outlined"
            margin="normal"
            size="small"
            fullWidth
            value={externalBrowserPath}
            onChange={e => {
                if (e.target.value === "~") {
                    setExternalBrowserPath(`"/Applications/Tor Browser.app/Contents/MacOS/firefox" --new-tab --allow-remote`)
                } else {
                    setExternalBrowserPath(e.target.value)
                }
            }}
        />
    }

    const DataLakeSecret = () => {
        return <TextField
            label="Production Secret Access Key"
            variant="outlined"
            margin="normal"
            size="small"
            type={showSecret ? 'text' : 'password'}
            fullWidth
            value={dataLakeSecret}
            onChange={e => setDataLakeSecret(e.target.value)}
            InputProps={{
                endAdornment: <InputAdornment position="end">
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowSecret}
                    >
                        {showSecret ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                </InputAdornment>
            }}
        />
    }

    return (
        <AppLayout title="Settings">
            { desktop &&
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Panel height={600}>
                            <form>
                                {SelectProfile()}
                                {SelectRegion()}
                                {DataLakeKey()}
                                {DataLakeSecret()}
                                {UseExternalBrowser()}
                                {ExternalBrowserPath()}
                                <SubmitButton onClick={handleSubmit}/>
                            </form>
                            <ErrorMessage apiError={props.apiError} requestId={settingsRequestId}/>
                            <Progress apiRequests={props.apiRequests} requestId={settingsRequestId}/>
                        </Panel>
                    </Grid>
                </Grid>
            }
            <ExPanel title={`Version: ${VERSION}`}>
                <Settings />
            </ExPanel>
        </AppLayout>
    )
}

const mapStateToProps = (state) => ({
    settingsRequestId: state.console.settingsRequestId,
    apiRequests: state.console.apiRequests,
    apiError: state.console.apiError,
    settings: state.console.settings
});

export default connect(mapStateToProps, {submitSettings, getSettings})(
    SettingsPage
);
