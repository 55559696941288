import React from 'react';

import JSONTree from "react-json-tree";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import {jsonTreeTheme} from "../utils/constants";
import Editor from "react-simple-code-editor";
import {highlight, languages} from "prismjs/components/prism-core";
import "prism-themes/themes/prism-material-light.css";

const flatViewStyle = {
    fontFamily: '"Fira code", "Fira Mono", monospace',
    fontSize: '14px',
    marginTop: '10px',
    marginBottom: '10px'
}

export default function JsonView({data, shouldExpandNode, colored, hide}) {
    const [treeView, setTreeView] = React.useState(true);
    const [coloredView, setColoredView] = React.useState(colored);

    if (hide) {
        return null;
    }

    const handleTreeViewChange = () => {
        setTreeView(!treeView);
    }

    const handleColoredViewChange = () => {
        setColoredView(!coloredView);
    }

    const TreeSwitch = () => {
        if (!data) {
            return null;
        }
        return (
            <FormControlLabel
                control={
                    <Switch
                        checked={treeView}
                        onChange={handleTreeViewChange}
                        name="JSONTree"
                        color="primary"
                    />
                }
                label="Tree View"
            />
        )
    }

    const ColoredSwitch = () => {
        if (!data || treeView) {
            return null;
        }
        return (
            <FormControlLabel
                control={
                    <Switch
                        checked={coloredView}
                        onChange={handleColoredViewChange}
                        name="Colored"
                        color="primary"
                    />
                }
                label="Colored View"
            />
        )
    }

    const FlatView = () => {
        if (treeView || !data) {
            return null;
        }
        const dataStr = JSON.stringify(data, null, 2)
        if (coloredView) {
            return <Editor
                value={dataStr}
                onValueChange={(code) => {}}
                highlight={(code) => highlight(code, languages.json, 'json')}
                style={flatViewStyle}
            />
        }
        return <pre style={flatViewStyle}>{dataStr}</pre>
    }

    const TreeView = () => {
        if (!treeView || !data) {
            return null;
        }
        return <JSONTree
            data={data}
            theme={jsonTreeTheme}
            shouldExpandNode={shouldExpandNode}
        />
    }

    return <>
        <TreeSwitch/>
        <ColoredSwitch/>
        <FlatView/>
        <TreeView/>
    </>

}