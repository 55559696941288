import React from 'react';
import { connect } from "react-redux";

import AppLayout from "../components/AppLayout";
import AuthTokenPanel from "../components/AuthTokenPanel";
import PartnersInfoPanel from "../components/PartnersInfoPanel";
import CreatePartnerPanel from "../components/CreatePartnerPanel";
import { getAuthToken, submitIpcRequest, openSignup } from "../store/actions/consoleActions";
import IndustryPanel from "../components/IndustryPanel";

function PartnersPage(props) {
    return (
        <AppLayout title="Partners">
            <CreatePartnerPanel
                submitRequest={props.submitIpcRequest}
            />
            <PartnersInfoPanel
                submitPartnersInfoRequest={(data, requestId) => props.submitIpcRequest('partners-info', data, requestId)}
                openSignup={props.openSignup}
                submitRequest={props.submitIpcRequest}
            />
            <IndustryPanel
                submitRequest={props.submitIpcRequest}
            />
            <AuthTokenPanel
                partner={true}
                getAuthToken={props.getAuthToken}
            />
        </AppLayout>
    )
}

export default connect(null, {
    getAuthToken,
    submitIpcRequest,
    openSignup
})(PartnersPage);
