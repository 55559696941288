import React from 'react';

import Grid from "@material-ui/core/Grid";

import ExPanel from "./ExPanel";
import {Button, DialogActions} from "@material-ui/core";
import useApiRequest from "../hooks/useApiRequest";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import FormInput from "./FormInput";
import useFields from "../hooks/useFields";
import {useSelector} from "react-redux";
import JsonView from "./JsonView";
import {DataGrid} from "@mui/x-data-grid";
import JsonSwitch from "./JsonSwitch";
import Link from "@material-ui/core/Link";

const SignupList = ({columns, loading, jsonView, signupList}) => {
    if (!signupList) {
        return null;
    }
    if (jsonView) {
        return <JsonView data={signupList} shouldExpandNode={(keyPath, data, level) => level < 3}/>
    }
    return <div style={{height: 400, width: '100%'}}>
        <DataGrid
            getRowId={(row) => row.signupLink}
            rows={signupList}
            hideFooterSelectedRowCount={true}
            autoPageSize={true}
            columns={columns}
            loading={loading}
            density='compact'
        />
    </div>
}

const formFields = {
    "accountId": {
        label: 'Partner',
        type: 'partner-select',
        initValue: ''
    },
    "merchantId": {
        label: 'Merchant ID',
        initValue: ''
    },
    "limit": {
        label: 'Limit',
        type: 'number',
        initValue: 200
    }
}

const fieldList = ["accountId", "merchantId", "limit"];

export default function SignupListPanel({submitRequest, openSignup}) {
    const signupList = useSelector(state => state.console.signupList);

    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();
    const [jsonView, setJsonView] = React.useState(false);
    const [fields, handleOnChange] = useFields(formFields);

    const handleInfoRequest = () => {
        submitRequest('signup-list-info', fields, newRequest());
    }

    const signupLinkCellRenderer = ({row, value}) => {
        return <>
            <Link href="#" color="primary" onClick={(e) => {
                e.preventDefault();
                openSignup(value, false);
            }}>
                {value}
            </Link>
        </>
    }

    const columns = [
        {field: 'merchantId', headerName: 'Merchant ID', width: 300},
        {field: 'createdAt', headerName: 'Created', width: 165},
        {field: 'status', headerName: 'Status', width: 200},
        {field: 'signupLink', headerName: 'Signup Link', width: 350, renderCell: signupLinkCellRenderer},
        {field: 'onboardingType', headerName: 'Onboarding Type', width: 150},
    ]

    return (
        <ExPanel title="Signup List">
            <Grid item xs={12}>
                <form>
                    {fieldList.map((name) => (
                        <FormInput
                            key={name}
                            name={name}
                            onChange={handleOnChange}
                            getEditValue={name => fields[name]}
                            formFields={formFields}
                        />
                    ))}
                </form>
                <DialogActions>
                    <Button disabled={requestInProgress} variant="contained" color="primary" onClick={handleInfoRequest}>Submit</Button>
                </DialogActions>
                <ErrorMessage errorMessage={requestError}/>
                <Progress show={requestInProgress} requestId={requestId}/>
                <JsonSwitch hide={!signupList} jsonView={jsonView} setJsonView={setJsonView}/>
                <SignupList signupList={signupList} jsonView={jsonView} columns={columns} loading={requestInProgress}/>
            </Grid>
        </ExPanel>
    )
}
