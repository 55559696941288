import 'date-fns';
import React from 'react';
import {useSelector} from "react-redux";

import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";
import {Avatar, Button, DialogActions} from "@material-ui/core";
import {DataGrid} from "@mui/x-data-grid";
import Link from "@material-ui/core/Link";

import ExPanel from "./ExPanel";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import useApiRequest from "../hooks/useApiRequest";
import JsonView from "./JsonView";
import JsonSwitch from "./JsonSwitch";
import {phoneNumberPattern} from "../utils/validators";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import Chip from "@material-ui/core/Chip";
import {useForm} from "react-hook-form";
import {FormInputCtr} from "./FormInputCtr";

const getRequestType = 'borrower-credit-file-list';
const cancelRequestType = 'borrower-credit-file-cancel';
const restoreRequestType = 'borrower-credit-file-restore';
const removeRequestType = 'borrower-credit-file-remove';

const formFields = {
    "mobileNumber": {
        label: 'Mobile Number',
        type: 'phone',
        rules: {
            required: 'Mobile number required',
            pattern: phoneNumberPattern
        },
        initValue: ''
    },
    "merchantId": {
        label: 'Merchant ID',
        initValue: ''
    },
    "actionDate": {
        label: 'Date From',
        type: 'date',
        initValue: null
    },
    "limit": {
        label: 'Limit',
        initValue: '20'
    }
}

const fieldList = ["mobileNumber", "merchantId", "actionDate", "limit"]

const BorrowerCreditReportList = ({columns, loading, jsonView, data}) => {
    if (!data) {
        return null;
    }
    if (jsonView) {
        return <JsonView data={data} shouldExpandNode={(keyPath, data, level) => level < 3}/>
    }
    return <div style={{height: 400, width: '100%'}}>
        <DataGrid
            getRowId={(row) => row.applicationId + row.applicationType}
            rows={data.creditReportList}
            hideFooterSelectedRowCount={true}
            autoPageSize={true}
            columns={columns}
            loading={loading}
            density='compact'
        />
    </div>
}

export default function BorrowerCreditFilePanel({submitRequest}) {
    const formsData = useSelector(state => state.console.formsData[getRequestType]);
    const settings = useSelector(state => state.console.settings);
    const notProduction = settings ? settings.profile !== 'wisetack' : false;

    const borrowerCreditReportList = useSelector(state => state.console.borrowerCreditReportList);
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();

    const [jsonView, setJsonView] = React.useState(false);

    const { handleSubmit, control, getValues } = useForm({
        defaultValues: {
            ...formsData
        }
    });

    const onSubmit = (values) => {
        submitRequest(getRequestType, values, newRequest());
    }

    const idCellRenderer = ({row, value}) => {
        const appType = row.applicationType === "PREQUAL_APPLICATION" ? "P":"L";
        return <CopyToClipboard text={value}><Chip
            label={value}
            avatar={<Avatar>{appType}</Avatar>}
            size="small"
            color="default"
        /></CopyToClipboard>
    }

    const cancelCellRenderer = ({row, value}) => {
        if (value) {
            return <>
                <Link title="Restore" href="#" color="primary" onClick={(e) => {
                    e.preventDefault();
                    submitRequest(restoreRequestType, {
                        mobileNumber: getValues('mobileNumber'),
                        merchantId: getValues('merchantId'),
                        applicationId: row.applicationId,
                        applicationType: row.applicationType,
                        limit: getValues('limit')
                    }, newRequest());
                }}>
                    {value}
                </Link>
            </>
        }
        return <>
            <Link href="#" color="primary" onClick={(e) => {
                e.preventDefault();
                submitRequest(cancelRequestType, {
                    mobileNumber: getValues('mobileNumber'),
                    merchantId: getValues('merchantId'),
                    applicationId: row.applicationId,
                    applicationType: row.applicationType,
                    limit: getValues('limit')
                }, newRequest());
            }}>
                CANCEL
            </Link>
            { notProduction &&
            <Link style={{marginLeft: "10px"}} href="#" color="secondary" onClick={(e) => {
                e.preventDefault();
                submitRequest(removeRequestType, {
                    mobileNumber: getValues('mobileNumber'),
                    merchantId: getValues('merchantId'),
                    applicationId: row.applicationId,
                    applicationType: row.applicationType,
                    limit: getValues('limit')
                }, newRequest());
            }}>
                REMOVE
            </Link>
            }
        </>
    }

    const columns = [
        {field: 'applicationId', headerName: 'Application ID', width: 320, renderCell: idCellRenderer},
        {field: 'merchantId', headerName: 'Merchant ID', width: 320},
        {field: 'createdAtUT', headerName: 'Created', width: 170},
        {field: 'canceledAt', headerName: 'Canceled', width: 170, renderCell: cancelCellRenderer},
    ]

    return (
        <ExPanel title="Borrower Credit Report">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid item xs={12}>
                    <form>
                        {fieldList.map((name) => (
                            <FormInputCtr
                                key={name}
                                name={name}
                                formFields={formFields}
                                control={control}
                                disabled={requestInProgress}
                            />
                        ))}
                    </form>
                    <DialogActions>
                        <Button disabled={requestInProgress} variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>Submit</Button>
                    </DialogActions>
                    <ErrorMessage errorMessage={requestError}/>
                    <Progress show={requestInProgress} requestId={requestId}/>
                    <JsonSwitch hide={!borrowerCreditReportList} jsonView={jsonView} setJsonView={setJsonView}/>
                    <BorrowerCreditReportList
                        data={borrowerCreditReportList}
                        jsonView={jsonView}
                        loading={requestInProgress}
                        columns={columns}
                    />
                </Grid>
            </MuiPickersUtilsProvider>
        </ExPanel>
    )
}

