import React from 'react';

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Chip from "@material-ui/core/Chip";
import {Checkbox, ListItemText, makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    updated: props => ({
        color: props.updated ? theme.palette.primary.main : theme.palette.text.primary
    }),
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: props => ({
        margin: 2,
        color: props.updated ? theme.palette.primary.main : theme.palette.text.primary
    })
}));

export default function SimpleSelect({label, value, name, onChange, data, multiple, disabled, updated, error}) {

    const classes = useStyles({updated});

    const renderValue = (value) => {
        if (Array.isArray(value)) {
            return <div className={classes.chips}>
                {value.map((item) => (
                    <Chip key={item} label={item} className={classes.chip} size="small" />
                ))}
            </div>
            // return value.join(', ')
        }
        return <span className={classes.updated}>{value}</span>;
    }
    return <FormControl variant="outlined" fullWidth margin="normal" disabled={disabled}>
        <InputLabel margin="dense">{label}</InputLabel>
        {!!data && <Select
            name={name}
            value={value}
            renderValue={renderValue}
            margin="dense"
            multiple={multiple}
            onChange={onChange}
            label={label}
            error={!!error}
        >
            {
                data.map((item) => {
                    if (multiple) {
                        let checked = false;
                        if (Array.isArray(value) && value.indexOf(item.id) > -1) {
                            checked = true;
                        }
                        return (
                            <MenuItem key={item.id} value={item.id}>
                                <Checkbox checked={checked} color="primary" />
                                <ListItemText primary={item.name || item.id}/>
                            </MenuItem>
                        )
                    }
                    return <MenuItem key={item.id} value={item.id}>{item.name || item.id}</MenuItem>
                })
            }
        </Select>}
        <div style={{color: "red"}}>{error}</div>
    </FormControl>
}
