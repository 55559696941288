import React, {useCallback} from "react";
import MaskedInput from "react-text-mask";
import {TextField} from "@material-ui/core";

export const MaskedTextField = ({mask, inputInterceptor, ...props}) => {
    const maskedInput = useCallback(({inputRef, onChange, ...props}) => {
        const handleOnChange = (e) => {
            if (inputInterceptor) {
                onChange(inputInterceptor(e))
            } else {
                onChange(e)
            }
        }
        return <MaskedInput
            {...props}
            onChange={handleOnChange}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null)
            }}
            placeholderChar={'\u2000'}
            mask={mask}
        />
    }, [mask, inputInterceptor]);

    return <TextField
        {...props}
        InputProps={{inputComponent: maskedInput}}
    />
}
