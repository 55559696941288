import React from 'react';
import {useSelector} from "react-redux";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import ExPanel from "./ExPanel";
import SubmitButton from "./SubmitButton";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import useApiRequest from "../hooks/useApiRequest";

export default function LoanReviewPanel({submitRequest}) {
    const loanReviewResult = useSelector(state => state.console.loanReviewResult);
    const [loanApplicationId, setLoanApplicationId] = React.useState('');
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();
    const [outcome, setOutcome] = React.useState('Approved');

    const handleLoanReviewRequest = () => {
        if (loanApplicationId) {
            submitRequest('loan-review', {loanApplicationId, outcome}, newRequest());
        }
    }

    const LoanReviewResult = () => {
        if (loanReviewResult && !requestInProgress) {
            return <div>{loanReviewResult}</div>
        }
        return null;
    }

    return (
        <ExPanel title="Loan Application Review" tooltip="Uses same endpoint as manual review from CRM">
            <Grid item xs={12}>
                <form>
                    <TextField
                        label="Loan Application ID"
                        variant="outlined"
                        margin="normal"
                        size="small"
                        required
                        fullWidth
                        value={loanApplicationId}
                        onChange={e => {setLoanApplicationId(e.target.value);}}
                    />
                    <FormControl variant="outlined">
                        <InputLabel id="select-outcome-label">Outcome</InputLabel>
                        <Select
                            labelId="select-outcome-label"
                            id="select-outcome"
                            value={outcome}
                            margin="dense"
                            onChange={e => setOutcome(e.target.value)}
                            label="Outcome"
                        >
                            <MenuItem value={"Approved"}>Approved</MenuItem>
                            <MenuItem value={"Denied"}>Denied</MenuItem>
                        </Select>
                    </FormControl>
                    <SubmitButton onClick={handleLoanReviewRequest}/>
                    <ErrorMessage errorMessage={requestError}/>
                    <Progress show={requestInProgress} requestId={requestId}/>
                    <LoanReviewResult />
                </form>
            </Grid>
        </ExPanel>
    )
}