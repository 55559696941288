import * as Yup from "yup";
import {transform} from "lodash-es";

export const zipValidator = Yup.string().matches(/(^\d{5}$)/, {message: 'Zip must be exactly 5 digits'})
export const bankRoutingNumberValidator = Yup.string().matches(/(^\d{9}$)/, {message: 'Bank routing number must be exactly 9 digits'})
export const bankAccountValidator = Yup.string().matches(/(^\d{4,20}$)/, {message: 'Invalid bank account number'})
export const stateValidator = Yup.string().matches(/((^A[LKSZRAEP]$)|(^C[AOT]$)|(^D[EC]$)|(^F[LM]$)|(^G[AU]$)|(^HI$)|(^I[ADLN]$)|(^K[SY]$)|(^LA$)|(^M[ADEHINOPST]$)|(^N[CDEHJMVY]$)|(^O[HKR]$)|(^P[ARW]$)|(^RI$)|(^S[CD]$)|(^T[NX]$)|(^UT$)|(^V[AIT]$)|(^W[AIVY]$))/, {message: 'Invalid state code'})
export const emailValidator = Yup.string().email('Invalid email address')
export const accountMaskValidator = Yup.string().matches(/(^\d{4}$)/, {message: 'Account mask must be exactly 4 digits'})
export const phoneNumberValidator = Yup.string().matches(/(^\+1\d{10}$)/, {message: 'Invalid phone number'})
export const ssn4Validator = Yup.string().matches(/(^\d{4}$)/, {message: 'SSN4 must be exactly 4 digits'})
export const ssnValidator = Yup.string().matches(/(^\d{9}$)/, {message: 'SSN must be exactly 9 digits'})
export const ownershipValidator = Yup.string().matches(/((^\d{1,2}%$)|(^100%$))/, {message: 'Invalid ownership %'})

export const requiredValidator = Yup.string().required('Required')

export const getValidationSchema = (formFields) => {
    const validators = transform(formFields, function(result, field, name) {
        if (field.validator) {
            if (field.required) {
                result[name] = field.validator.required('Required')
            } else {
                result[name] = field.validator
            }
        } else if (field.required) {
            result[name] = requiredValidator
        }
    })
    return Yup.object(validators);
}

export const phoneNumberPattern = {
    value: /^\+1-[0-9]{3}-[0-9]{3}-[0-9]{4}$/,
    message: 'Must use a valid phone number',
}
