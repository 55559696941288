import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
    reload: {
        marginBottom: '10px'
    },
}));

export default function ReloadButton({hide, onClick, disabled}) {
    const classes = useStyles();

    if (hide) {
        return null;
    }
    return <Button
        className={classes.reload}
        disabled={disabled}
        variant="contained"
        size="small"
        color="primary"
        onClick={onClick}
    >
        Reload
    </Button>
}