import React from 'react';
import {connect} from "react-redux";
import AppLayout from "../components/AppLayout";

import { submitIpcRequest } from "../store/actions/consoleActions";
import TableMetadataPanel from "../components/TableMetadataPanel";
import DataLakeQueryPanel from "../components/DataLakeQueryPanel";

function DataLakePage(props) {
    return (
        <AppLayout title="Data Lake">
            <TableMetadataPanel
                submitRequest={props.submitIpcRequest}
            />
            <DataLakeQueryPanel
                submitRequest={props.submitIpcRequest}
            />
        </AppLayout>
    )
}

export default connect(null, {
    submitIpcRequest
})(
    DataLakePage
);
